import { Col, Row } from 'antd';
import moment, { Moment } from 'moment';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Status } from '../../model/model';
import CustomContext from '../../service/CustomContext';
import errorService from '../../service/ErrorService';
import HeadMetadata from '../Helper/HeadMetadata/HeadMetadata';
import CalendarComponent from '../Shared/CalendarComponent/CalendarComponent';
import SidebarComponent from '../Shared/SidebarComponent/SidebarComponent';

class CalendarPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        if (!this.context.user) {
            return;
        }

        try {
            this.setState({ status: 'loading' });
            this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.match.params.date !== prevProps.match.params.date) {
            try {
                this.setState({ status: 'loading' });
                this.init();
            } catch (error) {
                errorService.displayMessage(error);
            } finally {
                this.setState({ status: undefined });
            }
        }
    }

    /** METHODS **/

    init = () => {
        const date = this.props.match.params.date ? moment.utc(this.props.match.params.date) : moment().utc();
        this.setState({ date });
    };

    /** COMPONENTS */

    renderHeader = (): JSX.Element => {
        return (
            <div className="panel-header">
                <div>
                    <h1>
                        <FormattedMessage id="navigation.calendar" />
                    </h1>
                    <p>
                        <FormattedMessage id="navigation.calendar.desc" />
                    </p>
                </div>
            </div>
        );
    };

    renderCalendar = (): JSX.Element => {
        return this.state.date ? <CalendarComponent date={this.state.date} /> : <></>;
    };

    render() {
        return (
            <>
                <HeadMetadata />
                <Row gutter={[24, 24]} type="flex">
                    <Col xs={24} xl={19}>
                        <div className="panel">
                            {this.renderHeader()}
                            {this.renderCalendar()}
                        </div>
                    </Col>
                    <Col xs={0} xl={5}>
                        <SidebarComponent />
                    </Col>
                </Row>
            </>
        );
    }
}
export default CalendarPage;

interface Props {
    match: any;
}

interface State {
    date?: Moment;
    status?: Status;
}

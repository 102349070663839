import React, { Component } from 'react';
import styles from './SubscriptionsPage.module.scss';
import { ScreenSizeProps, Plan, Subscription, Status, PlanStatus } from '../../model/model';
import { Row, Col, Icon, Tag, Button } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps, FormattedHTMLMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import withSizes from 'react-sizes';
import responsiveService from '../../service/ResponsiveService';
import errorService from '../../service/ErrorService';
import planApi from '../../api/PlanApi';
import subscriptionApi from '../../api/SubscriptionApi';
import CustomContext from '../../service/CustomContext';
import planService from '../../service/PlanService';
import dateService from '../../service/DateService';
import Loader from '../Loader/Loader';
import HeadMetadata from '../Helper/HeadMetadata/HeadMetadata';
import SidebarComponent from '../Shared/SidebarComponent/SidebarComponent';
import SubscriptionTrial from './SubscriptionPage/SubscriptionTrial/SubscriptionTrial';

class SubscriptionsPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            plans: [],
        };
    }

    async componentDidMount() {
        if (!this.context.user) {
            return;
        }

        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        const plans: Plan[] = await planApi.list();
        const subscriptions: Subscription[] = await subscriptionApi.list();
        const subscription: Subscription | undefined = subscriptions.length > 0 ? subscriptions[0] : undefined;
        this.setState({ plans, subscription });
    };

    /** COMPONENTS **/

    renderHeader = (): JSX.Element => {
        return (
            <div className={styles.header}>
                <FormattedMessage id="navigation.subscriptions" />
            </div>
        );
    };

    renderPlanCancel = (plan: Plan): JSX.Element => {
        if (this.state.subscription && this.state.subscription.cancelAt && this.state.subscription.planId === plan.id) {
            const format: string = dateService.getDateFormat(this.context.user);
            const cancelAtDate: string = this.state.subscription.cancelAt.format(format);
            return <FormattedMessage id="subscriptions.cancelAt" values={{ date: cancelAtDate }} />;
        } else {
            return <></>;
        }
    };

    renderPlanAction = (plan: Plan): JSX.Element => {
        const planStatus: PlanStatus = planService.getPlanStatus(plan, this.state.subscription);
        const planActionIcon: string = planService.getPlanActionIcon(planStatus);
        const planActionMessage: string = planService.getPlanActionMessage(planStatus);

        return (
            <Link key="upgrade" to={`/subscriptions/${plan.id}`}>
                <Button size="large" block data-test="visit">
                    <Icon type={planActionIcon} /> <FormattedMessage id={planActionMessage} />
                </Button>
            </Link>
        );
    };

    renderBasicPlan = (): JSX.Element => {
        return (
            <div className={`${styles.plan} ${styles.basic}`}>
                <h2>
                    <FormattedMessage id="homePrices.plans.basic.title" />
                </h2>
                <p className={styles.description}>
                    <FormattedMessage id="homePrices.plans.basic.description" />
                </p>
                <h3>
                    <FormattedHTMLMessage id="homePrices.plans.basic.price" />
                </h3>
                <p className={styles.monthlyPrice}>
                    <FormattedHTMLMessage id="homePrices.plans.basic.price.monthly.free" />
                </p>
                <Button size="large" block>
                    <FormattedMessage id="subscriptions.included" />
                </Button>
                <div className={styles.functionalities}>
                    <h4>
                        <FormattedMessage id="homePrices.plans.basic.functionalities" />
                    </h4>
                    <p>
                        <Icon type="check-circle" theme="filled" />
                        <FormattedMessage id="homePrices.plans.basic.functionalities.5" />
                    </p>
                    <p>
                        <Icon type="check-circle" theme="filled" />
                        <FormattedMessage id="homePrices.plans.basic.functionalities.6" />
                    </p>
                    <p>
                        <Icon type="check-circle" theme="filled" />
                        <FormattedMessage id="homePrices.plans.basic.functionalities.3" />
                    </p>
                </div>
            </div>
        );
    };

    renderStandardPlan = (standardPlan: Plan): JSX.Element => {
        const planStatus: PlanStatus = planService.getPlanStatus(standardPlan, this.state.subscription);
        return (
            <div className={`${styles.plan} ${styles.standard}`} data-test="standardPlan">
                <h2 data-test="planName">
                    <FormattedMessage id="homePrices.plans.standard.title" />
                    {planStatus === 'active' && (
                        <Tag color="#1aaa55">
                            <FormattedMessage id="subscriptions.active" />
                        </Tag>
                    )}
                </h2>
                <p className={styles.description}>
                    <FormattedMessage id="homePrices.plans.standard.description" />
                </p>
                <h3 data-test="planPrice">
                    <FormattedHTMLMessage
                        id="homePrices.plans.standard.price"
                        values={{
                            amount: standardPlan && standardPlan.amountWithVat ? standardPlan.amountWithVat / 100 : '',
                            currency: standardPlan && standardPlan.currency ? standardPlan.currency.toUpperCase() : '',
                        }}
                    />
                </h3>
                <p className={styles.monthlyPrice}>
                    <FormattedHTMLMessage
                        id="homePrices.plans.standard.price.monthly"
                        values={{
                            amount:
                                standardPlan && standardPlan.amountWithVat
                                    ? Math.floor(standardPlan.amountWithVat / 12) / 100
                                    : '',
                            currency: standardPlan && standardPlan.currency ? standardPlan.currency.toUpperCase() : '',
                        }}
                    />
                </p>
                {this.renderPlanAction(standardPlan)}
                <div className={styles.functionalities}>
                    <h4>
                        <FormattedMessage id="homePrices.plans.standard.functionalities" />
                    </h4>
                    <p data-test="planVideoconsultation">
                        <Icon type="check-circle" theme="filled" />
                        <FormattedMessage id="homePrices.plans.standard.functionalities.1" />
                    </p>
                    {/* <p data-test="planStorage">
                        <Icon type="check-circle" theme="filled" />
                        <FormattedMessage
                            id="homePrices.plans.standard.functionalities.2"
                            values={{ maxStorage: standardPlan ? standardPlan.maxStorage : '' }}
                        />
                    </p> */}
                    <p className={styles.cancel}>{this.renderPlanCancel(standardPlan)}</p>
                </div>
            </div>
        );
    };

    renderPremiumPlan = (premiumPlan: Plan): JSX.Element => {
        const planStatus: PlanStatus = planService.getPlanStatus(premiumPlan, this.state.subscription);
        return (
            <div className={`${styles.plan} ${styles.premium}`} data-test="premiumPlan">
                <h2 data-test="planName">
                    <FormattedMessage id="homePrices.plans.premium.title" />
                    {planStatus === 'active' && (
                        <Tag color="#1aaa55">
                            <FormattedMessage id="subscriptions.active" />
                        </Tag>
                    )}
                </h2>
                <p className={styles.description}>
                    <FormattedMessage id="homePrices.plans.premium.description" />
                </p>
                <h3 data-test="planPrice">
                    <FormattedHTMLMessage
                        id="homePrices.plans.premium.price"
                        values={{
                            amount: premiumPlan && premiumPlan.amountWithVat ? premiumPlan.amountWithVat / 100 : '',
                            currency: premiumPlan && premiumPlan.currency ? premiumPlan.currency.toUpperCase() : '',
                        }}
                    />
                </h3>
                <p className={styles.monthlyPrice}>
                    <FormattedHTMLMessage
                        id="homePrices.plans.premium.price.monthly"
                        values={{
                            amount:
                                premiumPlan && premiumPlan.amountWithVat
                                    ? Math.floor(premiumPlan.amountWithVat / 12) / 100
                                    : '',
                            currency: premiumPlan && premiumPlan.currency ? premiumPlan.currency.toUpperCase() : '',
                        }}
                    />
                </p>
                {this.renderPlanAction(premiumPlan)}
                <div className={styles.functionalities}>
                    <h4>
                        <FormattedMessage id="homePrices.plans.premium.functionalities" />
                    </h4>
                    <p>
                        <Icon type="check-circle" theme="filled" />
                        <FormattedMessage id="homePrices.plans.premium.functionalities.1" />
                    </p>
                    <p className={styles.cancel}>{this.renderPlanCancel(premiumPlan)}</p>
                    <p className={styles.extra} data-test="planExtra1">
                        <FormattedMessage id="homePrices.plans.premium.functionalities.extra.1" />
                    </p>
                </div>
            </div>
        );
    };

    renderSubscriptionCards = (): JSX.Element => {
        const standardPlan = this.state.plans.find(plan => plan.nickname === 'standard');
        const premiumPlan = this.state.plans.find(plan => plan.nickname === 'premium');

        return (
            <Row gutter={[24, 24]} type="flex" className={styles.plans}>
                <Col xs={24} lg={8}>
                    {this.renderBasicPlan()}
                </Col>
                <Col xs={24} lg={8}>
                    {standardPlan && this.renderStandardPlan(standardPlan)}
                </Col>
                <Col xs={24} lg={8}>
                    {premiumPlan && this.renderPremiumPlan(premiumPlan)}
                </Col>
            </Row>
        );
    };

    render() {
        return (
            <>
                <HeadMetadata />
                <div className={styles.layout}>
                    <Row gutter={[28, 24]} type="flex">
                        <Col xs={24} xl={19}>
                            {this.renderHeader()}
                            {this.state.status === 'loading' ? (
                                <Loader
                                    message={this.props.intl.formatMessage({ id: 'subscriptions.loading.title' })}
                                />
                            ) : (
                                <>
                                    <SubscriptionTrial />
                                    {this.renderSubscriptionCards()}
                                </>
                            )}
                        </Col>
                        <Col xs={0} xl={5}>
                            <SidebarComponent />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(SubscriptionsPage));

interface Props extends WrappedComponentProps, ScreenSizeProps {}

interface State {
    plans: Plan[];
    subscription?: Subscription;
    status?: Status;
}

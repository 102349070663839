import React, { Component } from 'react';
import styles from './CalendarDateComponent.module.scss';

import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
    Person,
    Status,
    DiaryRecord,
    ScreenSizeProps,
    DiaryAppointment,
    DiaryMedication,
} from '../../../../model/model';
import { Button, List, Row, Col, Modal, Icon } from 'antd';
import moment, { Moment } from 'moment';
import CustomContext from '../../../../service/CustomContext';
import errorService from '../../../../service/ErrorService';
import diaryRecordApi from '../../../../api/DiaryRecordApi';
import AvatarComponent from '../../AvatarComponent/AvatarComponent';
import responsiveService from '../../../../service/ResponsiveService';
import withSizes from 'react-sizes';
import FormattedMessageComponent from '../../FormattedMessageComponent';
import diaryAppointmentApi from '../../../../api/DiaryAppointmentApi';
import diaryMedicationApi from '../../../../api/DiaryMedicationApi';

class CalendarDateComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            date: moment(),
            items: [],
        };
    }

    async componentDidMount() {
        try {
            this.setState({ status: 'initiating' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    async componentDidUpdate(prevProps: Props) {
        if (this.props.date !== prevProps.date) {
            try {
                this.setState({ status: 'loading' });
                await this.init();
            } catch (error) {
                errorService.displayMessage(error);
            } finally {
                this.setState({ status: undefined });
            }
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        await this.list(this.props.date);
    };

    list = async (date: Moment) => {
        const from = date.clone().startOf('day');
        const to = date.clone().endOf('day');
        const personId = this.props.person ? this.props.person.id : undefined;
        const diaryRecords = await diaryRecordApi.list(from, to, personId);
        const diaryAppointments = await diaryAppointmentApi.list(from, to, personId);
        const diaryMedications = await diaryMedicationApi.list(from, to, personId);
        let items: Array<DiaryAppointment | DiaryRecord | DiaryMedication> = diaryAppointments
            .concat(diaryRecords)
            .concat(diaryMedications);
        items = items.sort((a, b) => {
            const aDate = ('startDate' in a ? a.startDate : (a as DiaryRecord).date) as Moment;
            const bDate = ('startDate' in b ? b.startDate : (b as DiaryRecord).date) as Moment;
            return aDate.isAfter(bDate) ? 1 : -1;
        });

        this.setState({ date, items });
    };

    getDatePath = (date: string): string => {
        const personId = this.props.person ? this.props.person.id : undefined;
        return personId ? `/persons/${personId}/diary/dates/${date}` : `/calendar/dates/${date}`;
    };

    getMonthPath = (date: string): string => {
        const personId = this.props.person ? this.props.person.id : undefined;
        return personId ? `/persons/${personId}/diary/months/${date}` : `/calendar/${date}`;
    };

    getAppointmentsPath = (): string => {
        const personId = this.props.person ? this.props.person.id : undefined;
        return personId ? `/persons/${personId}/diary/appointments` : `/calendar/appointments`;
    };

    getMedicationsPath = (): string => {
        const personId = this.props.person ? this.props.person.id : undefined;
        return personId ? `/persons/${personId}/diary/medications` : `/calendar/medications`;
    };

    getRecordsPath = (): string => {
        const personId = this.props.person ? this.props.person.id : undefined;
        return personId ? `/persons/${personId}/diary/records` : `/calendar/records`;
    };

    /** HANDLERS **/

    handleShowModal = () => {
        this.setState({ modalVisible: true });
    };

    handleHideModal = () => {
        this.setState({ modalVisible: false });
    };

    /** COMPONENTS */

    renderToolbar = (): JSX.Element => {
        return (
            <div className={styles.toolbar}>
                <div className={styles.dates}>{this.renderDatePicker()}</div>
                <div className={styles.add}>
                    {this.renderCalendarButton()}
                    {this.renderCreateButton()}
                </div>
            </div>
        );
    };

    renderDatePicker = (): JSX.Element => {
        const { date, status } = this.state;

        let formattedDate = '...';
        if (status !== 'initiating') {
            formattedDate =
                this.props.isXs || this.props.isSm ? date.format('DD MMM YYYY') : date.format('DD MMMM YYYY');
        }
        const day = date.format('YYYY-MM-DD');
        const yesterday = date
            .clone()
            .subtract(1, 'days')
            .format('YYYY-MM-DD');
        const tomorrow = date
            .clone()
            .add(1, 'days')
            .format('YYYY-MM-DD');

        return (
            <div className={styles.date}>
                <Link to={this.getDatePath(yesterday)}>
                    <Button icon="left" />
                </Link>
                <span>
                    <Link to={this.getMonthPath(day)}>{formattedDate}</Link>
                </span>
                <Link to={this.getDatePath(tomorrow)}>
                    <Button icon="right" />
                </Link>
            </div>
        );
    };

    renderCalendarButton = (): JSX.Element => {
        const day = this.state.date.format('YYYY-MM-DD');

        return (
            <Link to={this.getMonthPath(day)}>
                <Button type="default" icon="calendar" size="large">
                    {this.props.isXs || this.props.isSm ? '' : <FormattedMessage id="common.calendar" />}
                </Button>
            </Link>
        );
    };

    renderCreateButton = (): JSX.Element => {
        if (this.state.items.length > 0) {
            return (
                <>
                    <Button type="primary" icon="plus" size="large" onClick={this.handleShowModal}>
                        {this.props.isXs || this.props.isSm ? '' : <FormattedMessage id="common.add" />}
                    </Button>
                    {this.renderModal()}
                </>
            );
        } else {
            return <></>;
        }
    };

    renderModal = (): JSX.Element => {
        const date = this.state.date.format('YYYY-MM-DD');

        return (
            <Modal
                title={<FormattedMessage id="diaryDate.empty.title" />}
                visible={this.state.modalVisible}
                className={styles.modal}
                footer={<></>}
                onCancel={this.handleHideModal}
            >
                <Row gutter={[8, 8]} type="flex" className={styles.body}>
                    <Col xs={8}>
                        <Link to={`${this.getAppointmentsPath()}/new?date=${date}`}>
                            <div className={`${styles.card} ${styles.appointment}`} data-test="appointment">
                                <Icon type="calendar" />
                                <div>
                                    <FormattedMessage id="diaryDate.empty.diaryAppointment" />
                                </div>
                            </div>
                        </Link>
                    </Col>
                </Row>
            </Modal>
        );
    };

    renderEmpty = (): JSX.Element => {
        const date = this.state.date.format('YYYY-MM-DD');
        return (
            <div className={styles.empty}>
                <h2>
                    <FormattedMessage id="diaryDate.empty.title" />
                </h2>
                <Row gutter={[16, 16]} type="flex">
                    <Col xs={24} sm={8}>
                        <Link to={`${this.getAppointmentsPath()}/new?date=${date}`}>
                            <div className={`${styles.card} ${styles.appointment}`} data-test="appointment">
                                <Icon type="calendar" />
                                <div>
                                    <p>
                                        <FormattedMessage id="diaryDate.empty.diaryAppointment" />
                                    </p>
                                    <p>
                                        <FormattedMessageComponent id="diaryDate.empty.diaryAppointment.desc" />
                                    </p>
                                </div>
                            </div>
                        </Link>
                    </Col>
                </Row>
            </div>
        );
    };

    renderItem = (item: DiaryRecord | DiaryAppointment | DiaryMedication): JSX.Element => {
        const date = this.state.date.format('YYYY-MM-DD');
        if ('date' in item) {
            return (
                <Link to={`${this.getRecordsPath()}/${item.id}?date=${date}`} className={styles.description}>
                    <List.Item data-test="item">
                        <List.Item.Meta
                            avatar={<AvatarComponent size="large" name={item.name} />}
                            title={item.name}
                            description={
                                <>
                                    <FormattedMessage
                                        id="diaryDate.diaryRecord.desc"
                                        values={{
                                            time: item.date && moment(item.date).format('HH:mm'),
                                        }}
                                    />
                                </>
                            }
                        />
                    </List.Item>
                </Link>
            );
        } else if ('startDate' in item && 'remarks' in item) {
            return (
                <Link to={`${this.getMedicationsPath()}/${item.id}?date=${date}`} className={styles.description}>
                    <List.Item data-test="item">
                        <List.Item.Meta
                            avatar={<AvatarComponent size="large" name={item.name} />}
                            title={item.name}
                            description={
                                <>
                                    <FormattedMessage
                                        id="diaryDate.diaryMedication.desc"
                                        values={{
                                            time: item.startDate && moment(item.startDate).format('HH:mm'),
                                        }}
                                    />
                                </>
                            }
                        />
                    </List.Item>
                </Link>
            );
        } else if ('startDate' in item) {
            return (
                <Link to={`${this.getAppointmentsPath()}/${item.id}?date=${date}`} className={styles.description}>
                    <List.Item data-test="item">
                        <List.Item.Meta
                            avatar={<AvatarComponent size="large" name={item.name} />}
                            title={item.name}
                            description={
                                <>
                                    <FormattedMessage
                                        id="diaryDate.diaryAppointment.desc"
                                        values={{
                                            time: item.startDate && moment(item.startDate).format('HH:mm'),
                                        }}
                                    />
                                </>
                            }
                        />
                    </List.Item>
                </Link>
            );
        } else {
            return <></>;
        }
    };

    renderList = (): JSX.Element => {
        return (
            <List
                itemLayout="horizontal"
                dataSource={this.state.items}
                loading={this.state.status === 'loading'}
                locale={{
                    emptyText: this.renderEmpty(),
                }}
                renderItem={item => this.renderItem(item)}
            />
        );
    };

    render() {
        return (
            <div className={styles.diary}>
                {this.renderToolbar()}
                {this.renderList()}
            </div>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(CalendarDateComponent);

interface Props extends ScreenSizeProps {
    date: Moment;
    person?: Person;
}

interface State {
    date: Moment;
    items: Array<DiaryRecord | DiaryAppointment | DiaryMedication>;
    modalVisible?: boolean;
    status?: Status | 'initiating';
}

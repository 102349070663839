import React, { Component } from 'react';
import styles from './BiometricsPage.module.scss';
import { Layout, Button, Tabs, Select, message } from 'antd';
import CustomContext from '../../../service/CustomContext';
import {
    ChartType,
    Status,
    UserChart,
    UserMeasure,
    Person,
    StandardChart,
    TemplateChart,
    User,
    UserMeasureMetadata,
    ScreenSizeProps,
} from '../../../model/model';
import userChartApi from '../../../api/UserChartApi';
import UserMeasureModal from './UserMeasureModal/UserMeasureModal';
import settingsService from '../../../service/SettingsService';
import BiometricsChart from './BiometricsChart/BiometricsChart';
import unitService from '../../../service/UnitService';
import chartTemplateService from '../../../service/ChartTemplateService';
import BiometricsTable from './BiometricsTable/BiometricsTable';
import BiometricsMidparentalHeight from './BiometricsMidparentalHeight/BiometricsMidparentalHeight';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import errorService from '../../../service/ErrorService';
import dateService from '../../../service/DateService';
import userMeasureApi from '../../../api/UserMeasureApi';
import { Moment } from 'moment';
import withSizes from 'react-sizes';
import responsiveService from '../../../service/ResponsiveService';
import measureMetadataService from '../../../service/MeasureMetadataService';
import userService from '../../../service/UserService';

class BiometricsPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            templateCharts: [],
            userMeasureMetadatas: [],
        };
    }

    async componentDidMount() {
        if (!this.context.user) {
            return;
        }

        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        // get template charts
        const language: string = this.context.user && this.context.user.language ? this.context.user.language : 'EN';
        const templateCharts: TemplateChart[] = await chartTemplateService.listTemplateCharts(language);
        this.setState({ templateCharts });

        // get selected user chart
        const selectedChartType: ChartType = this.getDefaultChartType();
        this.selectUserChart(templateCharts, selectedChartType);
    };

    getDefaultChartType = (): ChartType => {
        const params = new URLSearchParams(window.location.search);
        let chartType = this.context.settings.chartTypes[0];
        if (
            params.get('chartType') &&
            settingsService.settings.chartTypes.map(ct => ct.value).includes(params.get('chartType') as string)
        ) {
            chartType = settingsService.getChartTypeByValue(params.get('chartType') as string);
        } else if (
            userService.getSelectedChartType() &&
            settingsService.settings.chartTypes
                .map(ct => ct.value)
                .includes(userService.getSelectedChartType() as string)
        ) {
            chartType = settingsService.getChartTypeByValue(userService.getSelectedChartType() as string);
        }

        return chartType;
    };

    selectUserChart = async (templateCharts: TemplateChart[], selectedChartType: ChartType): Promise<void> => {
        const user: User = this.context.user as User;
        // get selected user chart (including measures)
        const selectedUserChart: UserChart = chartTemplateService.getUserChartFromTemplateCharts(
            templateCharts,
            selectedChartType,
            this.props.person,
        );
        let userChart: UserChart = await userChartApi.get(selectedUserChart.id as number);
        userChart = unitService.convertChart(userChart, user.measurementSystem as string);
        this.setState({ selectedChartType, userChart });

        // get selected template for user chart
        const templateChartId: string | undefined = chartTemplateService.getTemplateChartIdByUserChart(userChart);
        this.selectTemplate(userChart, templateCharts, templateChartId);

        // set selected chart type for current user (used in next access)
        userService.setSelectedChartType(selectedChartType.value);
    };

    selectTemplate = async (
        userChart: UserChart,
        templateCharts: TemplateChart[],
        templateChartId: string | undefined,
    ): Promise<void> => {
        // get template chart
        const selectedTemplateChart: TemplateChart | undefined = chartTemplateService.getTemplateChartById(
            templateCharts,
            templateChartId,
        );

        // update selected template of user chart
        chartTemplateService.updateUserChartTemplate(userChart, selectedTemplateChart);

        // get template chart (including measures)
        const user: User = this.context.user as User;
        const measurementSystem: string = user.measurementSystem as string;
        const language: string = user.language as string;
        const templateChart: TemplateChart | undefined = await chartTemplateService.getTemplateChartWithMeasures(
            measurementSystem,
            language,
            this.props.person,
            selectedTemplateChart,
        );
        this.setState({ templateChart });

        // list user measures metadatas
        const birthdate: Moment = this.props.person.birthdate as Moment;
        const standardChart: StandardChart | undefined =
            templateChart && templateChart.type === 'standard' ? (templateChart.chart as StandardChart) : undefined;
        this.listUserMeasureMetadata(birthdate, userChart, standardChart);
    };

    saveUserMeasure = async (userMeasure: UserMeasure) => {
        userMeasure = userMeasure.id
            ? await userMeasureApi.update(userMeasure)
            : await userMeasureApi.create(userMeasure);
        this.refreshUserMeasures();
    };

    deleteUserMeasure = async (userMeasure: UserMeasure) => {
        await userMeasureApi.delete(userMeasure);
        this.refreshUserMeasures();
    };

    refreshUserMeasures = async (): Promise<void> => {
        const user: User = this.context.user as User;
        let userChart: UserChart = this.state.userChart as UserChart;
        userChart = await userChartApi.get(userChart.id as number);
        userChart = unitService.convertChart(userChart, user.measurementSystem as string);
        this.setState({ userChart });

        // list user measures metadatas
        const birthdate: Moment = this.props.person.birthdate as Moment;
        const standardChart: StandardChart | undefined =
            this.state.templateChart && this.state.templateChart.type === 'standard'
                ? (this.state.templateChart.chart as StandardChart)
                : undefined;
        this.listUserMeasureMetadata(birthdate, userChart, standardChart);
    };

    listUserMeasureMetadata = async (birthdate: Moment, userChart: UserChart, standardChart?: StandardChart) => {
        const userMeasureMetadatas = await measureMetadataService.list(
            userChart.series[0].measures,
            standardChart,
            birthdate,
        );
        this.setState({ userMeasureMetadatas });
    };

    /** HANDLERS **/

    handleSelectChartType = (chartType: string): void => {
        try {
            this.selectUserChart(this.state.templateCharts, settingsService.getChartTypeByValue(chartType));
        } catch (error) {
            errorService.displayMessage(error);
        }
    };

    handleSelectTemplate = (templateChartId: string): void => {
        try {
            const selectedTemplateChartId: string | undefined = templateChartId === '0' ? undefined : templateChartId;
            this.selectTemplate(this.state.userChart as UserChart, this.state.templateCharts, selectedTemplateChartId);
        } catch (error) {
            errorService.displayMessage(error);
        }
    };

    handleShowUserMeasure = async (userMeasure?: UserMeasure): Promise<void> => {
        this.setState({ userMeasureVisible: true, userMeasure });
    };

    handleSaveUserMeasure = async (userMeasure: UserMeasure): Promise<void> => {
        try {
            await this.saveUserMeasure(userMeasure);
            message.success(this.props.intl.formatMessage({ id: 'common.notification.saved' }), 0.7);
            this.setState({ userMeasureVisible: false, userMeasure: {} });
        } catch (error) {
            errorService.displayMessage(error, [[409, 'biometrics.measure.error.duplicated']]);
        }
    };

    handleDeleteUserMeasure = async (userMeasure: UserMeasure): Promise<void> => {
        try {
            await this.deleteUserMeasure(userMeasure);
            message.success(this.props.intl.formatMessage({ id: 'common.notification.deleted' }), 0.7);
            this.setState({ userMeasureVisible: false, userMeasure: {} });
        } catch (error) {
            errorService.displayMessage(error);
        }
    };

    handleCloseUserMeasure = (): void => {
        this.setState({ userMeasureVisible: false, userMeasure: {} });
    };

    /** COMPONENTS **/

    renderToolbar = (): JSX.Element => {
        const { userChart } = this.state;

        return (
            <div className={styles.toolbar}>
                <div className={styles.selector}>{this.renderChartSelector()}</div>
                <div
                    className={styles.add}
                    hidden={this.state.selectedChartType && this.state.selectedChartType.value === 'MIDPARENTAL_HEIGHT'}
                >
                    <Button
                        type="primary"
                        size="large"
                        icon="plus"
                        onClick={e => this.handleShowUserMeasure()}
                        disabled={!userChart}
                        data-test="addMeasure"
                    >
                        {this.props.isXs || this.props.isSm ? '' : <FormattedMessage id="biometrics.addMeasure" />}
                    </Button>
                </div>
            </div>
        );
    };

    renderChartSelector = (): JSX.Element => {
        const chartOptions = Array.from(settingsService.listChartTypesByMeasurement(), ([measurement, chartTypes]) => (
            <Select.OptGroup label={<FormattedMessage id={`measurementType.${measurement}`} />} key={measurement}>
                {chartTypes.map(chartType => (
                    <Select.Option value={chartType.value} key={chartType.value}>
                        {chartType.label}
                    </Select.Option>
                ))}
            </Select.OptGroup>
        ));
        const chartType: string | undefined = this.state.selectedChartType && this.state.selectedChartType.value;

        return (
            <Select size="large" value={chartType} onChange={this.handleSelectChartType} data-test="chartType">
                {chartOptions}
            </Select>
        );
    };

    renderChartTemplateSelector = (): JSX.Element => {
        const selectedTemplateChartId: string | undefined = this.state.templateChart && this.state.templateChart.id;
        const userChart: UserChart = this.state.userChart as UserChart;
        const selectedChartTypeValue: string | undefined =
            this.state.selectedChartType && this.state.selectedChartType.value;
        const standardChartOptions = chartTemplateService
            .listTemplateChartsByType(this.state.templateCharts, 'standard')
            .filter(
                tc =>
                    (tc.chart as StandardChart).chartType === selectedChartTypeValue &&
                    (tc.chart as StandardChart).gender === this.props.person.gender,
            )
            .map(tc => (
                <Select.Option value={tc.id} key={tc.id}>
                    {tc.name}
                </Select.Option>
            ));
        const userChartOptions = chartTemplateService
            .listTemplateChartsByType(this.state.templateCharts, 'user')
            .filter(tc => tc.chart.chartType === selectedChartTypeValue && tc.chart.id !== userChart.id)
            .map(tc => (
                <Select.Option value={tc.id} key={tc.id}>
                    {tc.name}
                </Select.Option>
            ));

        return (
            <Select
                size="large"
                allowClear
                placeholder={<FormattedMessage id="biometrics.template.placeholder" />}
                value={selectedTemplateChartId}
                onChange={this.handleSelectTemplate}
                className={styles.template}
                data-test="chartTemplate"
            >
                {standardChartOptions}
                {userChartOptions.length > 0 && <Select.OptGroup label="Persons">{userChartOptions}</Select.OptGroup>}
            </Select>
        );
    };

    renderChart = (): JSX.Element => {
        const user: User = this.context.user as User;
        const dateFormat: string = dateService.getDateFormat(this.context.user);
        const measurementSystem: string = user.measurementSystem as string;
        const userChart: UserChart = this.state.userChart as UserChart;
        return (
            <>
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane
                        tab={
                            <span data-test="chartTab">
                                <FormattedMessage id="biometrics.tabs.chart" />
                            </span>
                        }
                        key="1"
                    >
                        <BiometricsChart
                            measurementSystem={measurementSystem}
                            person={this.props.person}
                            userChart={userChart}
                            templateChart={this.state.templateChart}
                        />
                        {this.renderChartTemplateSelector()}
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={
                            <span data-test="measuresTab">
                                <FormattedMessage id="biometrics.tabs.measures" />
                            </span>
                        }
                        key="2"
                        data-test="measuresTab"
                    >
                        <BiometricsTable
                            measurementSystem={measurementSystem}
                            dateFormat={dateFormat}
                            person={this.props.person}
                            userChart={userChart}
                            userMeasureMetadatas={this.state.userMeasureMetadatas}
                            status={this.state.status}
                            showUserMeasure={this.handleShowUserMeasure}
                        />
                    </Tabs.TabPane>
                </Tabs>
            </>
        );
    };

    renderMeasureModal = (): JSX.Element => {
        if (this.state.userChart) {
            const user: User = this.context.user as User;
            const dateFormat: string = dateService.getDateFormat(user);
            const measurementSystem: string = user.measurementSystem as string;
            const standardChart: StandardChart | undefined =
                this.state.templateChart && this.state.templateChart.type === 'standard'
                    ? (this.state.templateChart.chart as StandardChart)
                    : undefined;

            return (
                <UserMeasureModal
                    visible={this.state.userMeasureVisible}
                    measurementSystem={measurementSystem}
                    dateFormat={dateFormat}
                    person={this.props.person}
                    readonly={
                        !!this.state.userMeasure &&
                        this.state.userMeasure.seriesId !== this.state.userChart.series[0].id
                    }
                    userMeasure={this.state.userMeasure}
                    userChart={this.state.userChart as UserChart}
                    standardChart={standardChart}
                    saveUserMeasure={this.handleSaveUserMeasure}
                    deleteUserMeasure={this.handleDeleteUserMeasure}
                    closeUserMeasure={this.handleCloseUserMeasure}
                />
            );
        } else {
            return <></>;
        }
    };

    renderBiometric = (): JSX.Element => {
        if (this.state.selectedChartType && this.state.selectedChartType.value === 'MIDPARENTAL_HEIGHT') {
            const user: User = this.context.user as User;
            const measurementSystem: string = user.measurementSystem as string;
            return <BiometricsMidparentalHeight measurementSystem={measurementSystem} person={this.props.person} />;
        } else {
            return this.renderChart();
        }
    };

    render() {
        return (
            <>
                <Layout.Content>
                    <p className={styles.warning}>
                        <FormattedMessage id="tools.warning" />
                    </p>
                    {this.renderToolbar()}
                    {this.renderBiometric()}
                </Layout.Content>
                {this.renderMeasureModal()}
            </>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(BiometricsPage));

interface Props extends WrappedComponentProps, ScreenSizeProps {
    person: Person;
}

interface State {
    selectedChartType?: ChartType;
    templateCharts: TemplateChart[];
    userChart?: UserChart;
    templateChart?: TemplateChart;
    userMeasureVisible?: boolean;
    userMeasure?: UserMeasure;
    userMeasureMetadatas: UserMeasureMetadata[];
    status?: Status;
}

import React, { Component } from 'react';
import styles from './CommunityHeaderComponent.module.scss';
import { FormattedMessage } from 'react-intl';
import { MedicalConditionType, ActionStatus, User, ScreenSizeProps } from '../../../model/model';
import { Link } from 'react-router-dom';
import SignUpModal from '../../Shared/SignUpModal/SignUpModal';
import { Button, Popconfirm, message, Icon, Tooltip } from 'antd';
import userApi from '../../../api/UserApi';
import errorService from '../../../service/ErrorService';
import CustomContext from '../../../service/CustomContext';
import withSizes from 'react-sizes';
import responsiveService from '../../../service/ResponsiveService';
import pathService from '../../../service/PathService';

class CommunityHeaderComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    /** METHODS **/

    updateUserCondition = async () => {
        if (this.context.user) {
            let user: User = await userApi.getCurrentUser();
            user.admin = undefined;
            user.associate = undefined;
            let conditions: string[] = user.conditions || [];
            if (conditions.includes(this.props.condition.value)) {
                user.conditions = conditions.filter(c => c !== this.props.condition.value);
            } else if (this.props.condition) {
                user.conditions = conditions.concat([this.props.condition.value]);
            }
            user = await userApi.update(user);

            // refresh user
            const updatedUser = Object.assign({}, this.context.user, user);
            this.context.updateUser && this.context.updateUser(updatedUser);
        } else {
            this.setState({ signUpVisible: true });
        }
    };

    /** HANDLERS **/

    handleJoin = async () => {
        try {
            message.destroy();
            this.setState({ actionStatus: 'saving' });
            await this.updateUserCondition();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    handleHideSignUp = () => {
        this.setState({ signUpVisible: false });
    };

    /** COMPONENTS **/

    renderBackButton = (): JSX.Element => {
        return (
            <Link to={pathService.getPath('communities')}>
                <Button icon="arrow-left" type="link"></Button>
            </Link>
        );
    };

    renderJoinButton = (): JSX.Element => {
        if (
            this.context.user &&
            this.context.user.conditions &&
            this.context.user.conditions.includes(this.props.condition.value)
        ) {
            return (
                <Popconfirm
                    title={<FormattedMessage id="community.header.join.confirm" />}
                    onConfirm={this.handleJoin}
                    okText={<FormattedMessage id="common.ok" />}
                    cancelText={<FormattedMessage id="common.cancel" />}
                >
                    <Button type="default" className={styles.selected}>
                        <Icon type="heart" theme="filled" />
                    </Button>
                </Popconfirm>
            );
        } else {
            return (
                <Tooltip title={<FormattedMessage id="community.header.join" />}>
                    <Button icon="heart" type="default" onClick={this.handleJoin} />
                </Tooltip>
            );
        }
    };

    renderButtons = (): JSX.Element => {
        return (
            <>
                {this.renderBackButton()}
                {this.renderJoinButton()}
            </>
        );
    };

    render() {
        return (
            <div className="panel-header">
                <div>
                    <h1>{this.props.condition.label}</h1>
                </div>
                <div className="panel-header-back">
                    {this.renderButtons()}
                    <SignUpModal visible={this.state.signUpVisible} handleClose={this.handleHideSignUp} />
                </div>
            </div>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(CommunityHeaderComponent);

interface Props extends ScreenSizeProps {
    condition: MedicalConditionType;
    button?: React.ReactElement;
}

interface State {
    signUpVisible?: boolean;
    actionStatus?: ActionStatus;
}

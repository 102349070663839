import { Button, Col, Row } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import CustomContext from '../../service/CustomContext';
import pathService from '../../service/PathService';
import HeadMetadata from '../Helper/HeadMetadata/HeadMetadata';
import HomeDemoComponent from './HomeDemoComponent/HomeDemoComponent';
import styles from './HomePage.module.scss';
import charts from './images/charts-1.png';
import charts2 from './images/charts-2.png';
import charts3 from './images/charts-3.png';
import chat from './images/chat.png';
import children from './images/children.png';
import dashboard from './images/dashboard.png';
import doctors from './images/doctors.png';
import documents from './images/documents-1.png';
import { default as calendar, default as form } from './images/form-1.png';

class HomePage extends Component<any, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    readonly mobiles: string[] = [chat, charts, form, charts2, charts3, documents, calendar, dashboard];

    constructor(props: any) {
        super(props);
        this.state = {
            mobileIndex: 0,
        };
    }

    componentDidMount() {
        setInterval(() => {
            const mobileIndex: number =
                this.state.mobileIndex === this.mobiles.length - 1 ? 0 : this.state.mobileIndex + 1;
            this.setState({ mobileIndex });
        }, 3000);
    }

    /** COMPONENTS **/

    render() {
        return (
            <>
                <HeadMetadata />
                <div>
                    <div className={styles.top}>
                        <div className={styles.back}></div>
                        <Row className={styles.headline} type="flex" justify="center" align="middle">
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <h1>
                                    <FormattedMessage id="home.title" />
                                </h1>
                                <h2>
                                    <FormattedMessage id="home.subtitle" />
                                </h2>

                                <div className={styles.buttons}>
                                    {this.context.user ? (
                                        <Link to="/signup">
                                            <Button type="primary" className={styles.button}>
                                                <FormattedMessage id="home.dashboard" />
                                            </Button>
                                        </Link>
                                    ) : (
                                        <Link to={pathService.getPath('growth-chart')}>
                                            <Button type="primary" className={styles.button}>
                                                <FormattedMessage id="home.tools" />
                                            </Button>
                                        </Link>
                                    )}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <img src={this.mobiles[this.state.mobileIndex]} alt="chat" />
                            </Col>
                        </Row>
                    </div>

                    <Row className={`${styles.section} ${styles.doctors}`} type="flex" justify="center" align="middle">
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} className={styles.image}>
                            <img src={doctors} alt="people" />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} className={styles.message}>
                            <div>
                                <h3>
                                    <Link to={pathService.getPath('professionals')}>
                                        <FormattedMessage id="home.doctors.title" />
                                    </Link>
                                </h3>
                                <p>
                                    <FormattedMessage id="home.doctors.subtitle1" />
                                </p>
                                <p>
                                    <FormattedMessage id="home.doctors.subtitle2" />
                                </p>
                                <p>
                                    <FormattedMessage id="home.doctors.subtitle3" />
                                </p>
                                <p>
                                    <FormattedMessage id="home.doctors.subtitle5" />
                                </p>
                                <p className={styles.link} hidden={true}>
                                    <Link to={pathService.getPath('professionals')}>
                                        <Button type="primary" size="large">
                                            <FormattedMessage id="home.seeMore" />
                                        </Button>
                                    </Link>
                                </p>
                            </div>
                        </Col>
                    </Row>

                    <Row className={`${styles.section} ${styles.patients}`} type="flex" justify="center" align="middle">
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} className={styles.message}>
                            <div>
                                <h3>
                                    <Link to={pathService.getPath('patients')}>
                                        <FormattedMessage id="home.patients.title" />
                                    </Link>
                                </h3>
                                <p>
                                    <FormattedMessage id="home.patients.subtitle1" />
                                </p>
                                <p>
                                    <FormattedMessage id="home.patients.subtitle3" />
                                </p>
                                <p>
                                    <FormattedMessage id="home.patients.subtitle4" />
                                </p>
                                <p>
                                    <FormattedMessage id="home.patients.subtitle5" />
                                </p>
                                <p className={styles.link} hidden={true}>
                                    <Link to={pathService.getPath('patients')}>
                                        <Button type="primary" size="large">
                                            <FormattedMessage id="home.seeMore" />
                                        </Button>
                                    </Link>
                                </p>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12} className={styles.image}>
                            <img src={children} className={styles.children} alt="children" />
                        </Col>
                    </Row>

                    <HomeDemoComponent theme="dark" />
                </div>
            </>
        );
    }
}
export default HomePage;

interface State {
    mobileIndex: number;
}

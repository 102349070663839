import { AuthUser } from '../model/model';

class SuperUserService {
    readonly superusers = ['migelct@hotmail.com', 'szczes@gmail.com', 'support@walfen.com'];

    isSuperUser = (auth: AuthUser | undefined): boolean => {
        return !!auth && !!auth.email && this.superusers.includes(auth.email);
    };
}

const superUserService = new SuperUserService();
export default superUserService;

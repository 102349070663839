import { Button, Col, DatePicker, Form, Row, Select, Tabs, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import moment, { Moment } from 'moment';
import React, { Component } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import withSizes from 'react-sizes';
import standardChartApi from '../../../api/StandardChartApi';
import {
    ChartType,
    CustomType,
    ExternalUser,
    Person,
    ScreenSizeProps,
    StandardChart,
    Status,
    TemplateChart,
    UnitType,
    UserChart,
    UserMeasure,
    UserMeasureMetadata,
} from '../../../model/model';
import chartService from '../../../service/ChartService';
import chartTemplateService from '../../../service/ChartTemplateService';
import CustomContext from '../../../service/CustomContext';
import dateService from '../../../service/DateService';
import errorService from '../../../service/ErrorService';
import externalMeasureService from '../../../service/ExternalMeasureService';
import externalUserService from '../../../service/ExternalUserService';
import i18nService from '../../../service/I18nService';
import measureMetadataService from '../../../service/MeasureMetadataService';
import pathService from '../../../service/PathService';
import responsiveService from '../../../service/ResponsiveService';
import settingsService from '../../../service/SettingsService';
import unitService from '../../../service/UnitService';
import ga from '../../Helper/GoogleAnalytics/GoogleAnalytics';
import HeadMetadata from '../../Helper/HeadMetadata/HeadMetadata';
import BiometricsChart from '../../PersonPage/BiometricsPage/BiometricsChart/BiometricsChart';
import BiometricsTable from '../../PersonPage/BiometricsPage/BiometricsTable/BiometricsTable';
import UserMeasureModal from '../../PersonPage/BiometricsPage/UserMeasureModal/UserMeasureModal';
import GoogleAdsComponent from '../../Shared/GoogleAdsComponent/GoogleAdsComponent';
import SidebarComponent from '../../Shared/SidebarComponent/SidebarComponent';
import styles from './GrowthChartPage.module.scss';
import GoogleAdsNewsComponent from '../../Shared/GoogleAdsNewsComponent/GoogleAdsNewsComponent';

class GrowthChartPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            chartTypes: [],
            measurementSystemTypes: [],
            genderTypes: [],
            standardCharts: [],
            availableStandardCharts: [],
            userMeasureMetadatas: [],
        };
    }

    componentDidMount() {
        try {
            this.setState({ status: 'loading' });
            ga.createDefaultEvent('growth chart', 'growth chart - init');
            this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = (): void => {
        const chartTypes: ChartType[] = settingsService.settings.chartTypes.filter(ct =>
            ['HEIGHT', 'WEIGHT', 'BMI', 'HEAD_CIRCUMFERENCE', 'ARM_CIRCUMFERENCE'].includes(ct.value),
        );
        const measurementSystemTypes: CustomType[] = settingsService.settings.measurementSystemTypes;
        const genderTypes: CustomType[] = settingsService.settings.genderTypes;

        const chartType: string = this.props.type === 'growthChart' ? 'HEIGHT' : 'BMI';
        const measurementSystem: string = measurementSystemTypes[0].value;
        const gender: string = genderTypes[0].value;
        const birthdate: Moment = moment()
            .startOf('day')
            .subtract(this.props.type === 'bmiTracker' ? 19 : 5, 'years');
        const userChart: UserChart = this.createUserChart(chartType);

        this.setState({
            chartTypes,
            chartType,
            measurementSystemTypes,
            measurementSystem,
            genderTypes,
            gender,
            birthdate,
            userChart,
        });

        this.initStandardCharts(chartType, gender, birthdate, measurementSystem);
    };

    initStandardCharts = async (
        chartType: string,
        gender: string,
        birthdate: Moment,
        measurementSystem: string,
    ): Promise<void> => {
        const language: string = i18nService.getLocaleFromPath().toUpperCase();
        const standardCharts: StandardChart[] = await standardChartApi.list(language);
        const availableStandardCharts: StandardChart[] = this.listAvailableStandardCharts(
            standardCharts,
            chartType,
            gender,
        );
        const standardChart: StandardChart | undefined = await this.getStandardChart(
            birthdate,
            measurementSystem,
            chartType,
            availableStandardCharts[0].id,
        );

        this.setState({
            standardCharts,
            availableStandardCharts,
            standardChart,
        });
    };

    listAvailableStandardCharts = (
        standardCharts: StandardChart[],
        chartType: string,
        gender: string,
    ): StandardChart[] => {
        return standardCharts.filter(sc => sc.chartType === chartType && sc.gender === gender);
    };

    listUserMeasureMetadata = async (birthdate: Moment, userChart: UserChart, standardChart?: StandardChart) => {
        const userMeasureMetadatas = await measureMetadataService.list(
            userChart.series[0].measures,
            standardChart,
            birthdate,
        );
        this.setState({ userMeasureMetadatas });
    };

    getStandardChart = async (
        birthdate: Moment,
        measurementSystem: string,
        chartType?: string,
        id?: number,
    ): Promise<StandardChart | undefined> => {
        let standardChart: StandardChart | undefined;
        if (id) {
            const language: string = i18nService.getLocaleFromPath().toUpperCase();
            const maxDate: Moment = dateService.calculateMaxDate(birthdate, moment().utc(), chartType);
            const defaultSeries = chartTemplateService.listDefaultSeries(chartType);
            standardChart = await standardChartApi.get(id, language, birthdate, maxDate, undefined, defaultSeries);
            standardChart = unitService.convertChart(standardChart, measurementSystem) as StandardChart;

            const adultSeries = chartTemplateService.listStandardAdultSeries(standardChart.chartType);
            standardChart.series = standardChart.series.concat(adultSeries);
        }

        return standardChart;
    };

    createUserMeasure = async (userMeasure: UserMeasure) => {
        const userChart: UserChart = Object.assign({}, this.state.userChart);

        // delete existing user measure (same id or date)
        userChart.series[0].measures = chartService.filterDuplicated(userChart.series[0].measures, userMeasure);

        // add user measure to chart
        userMeasure.id = Math.random();
        userMeasure.date = moment(userMeasure.date);
        userMeasure.time = userMeasure.time ? moment(userMeasure.time) : undefined;
        userChart.series[0].measures.push(userMeasure);

        // save external user measuse
        const externalUser: ExternalUser = externalUserService.createExternalUser();
        this.saveExternalUserMeasure(userMeasure, externalUser);

        // refresh metadatas of user measures
        this.listUserMeasureMetadata(this.state.birthdate as Moment, userChart, this.state.standardChart);

        this.setState({ userChart, userMeasureVisible: false, userMeasure: {} });
    };

    saveExternalUserMeasure = (userMeasure: UserMeasure, externalUser: ExternalUser) => {
        const userChart = this.state.userChart as UserChart;
        externalMeasureService.saveExternalUserMeasure(
            'GC',
            userChart.chartType as string,
            userMeasure,
            externalUser,
            this.state.gender as string,
            this.state.birthdate as Moment,
        );
    };

    saveExternalUserMeasures = () => {
        if (this.state.userChart) {
            externalUserService.setExternalAction('GC');
            const externalUser: ExternalUser = externalUserService.createExternalUser(true);
            this.state.userChart.series[0].measures.forEach(userMeasure => {
                this.saveExternalUserMeasure(userMeasure, externalUser);
            });
        }
    };

    deleteUserMeasure = async (userMeasure: UserMeasure) => {
        const userChart: UserChart = Object.assign({}, this.state.userChart);
        userChart.series[0].measures = userChart.series[0].measures.filter(um => um.id !== userMeasure.id);
        this.setState({ userChart, userMeasureVisible: false, userMeasure: {} });
    };

    createUserChart = (chartType: string): UserChart => {
        return {
            id: 0,
            chartType,
            series: [{ measures: [] }],
        };
    };

    getChartHeight = (): number => {
        let height: number;
        if (this.props.isMd) {
            height = 100;
        } else if (this.props.isLg) {
            height = 140;
        } else {
            height = 300;
        }

        return height;
    };

    /** HANDLERS **/

    handleChangeChartType = async (chartType: string): Promise<void> => {
        try {
            ga.createDefaultEvent('growth chart', 'growth chart - change chart type', chartType);
            const availableStandardCharts: StandardChart[] = this.listAvailableStandardCharts(
                this.state.standardCharts,
                chartType,
                this.state.gender as string,
            );
            let standardChart: StandardChart | undefined;
            if (availableStandardCharts.length > 0) {
                standardChart = await this.getStandardChart(
                    this.state.birthdate as Moment,
                    this.state.measurementSystem as string,
                    chartType,
                    availableStandardCharts[0].id,
                );
            }
            const userChart: UserChart = this.createUserChart(chartType);
            this.listUserMeasureMetadata(this.state.birthdate as Moment, userChart, standardChart);
            this.setState({ chartType, availableStandardCharts, standardChart, userChart });
        } catch (error) {
            errorService.displayMessage(error);
            ga.createDefaultEvent('growth chart', 'growth chart - change chart type - error');
        }
    };

    handleChangeMeasurementSystem = (measurementSystem: string): void => {
        try {
            ga.createDefaultEvent('growth chart', 'growth chart - change measurement system', measurementSystem);
            let standardChart: StandardChart | undefined;
            if (this.state.standardChart) {
                standardChart = unitService.convertChart(this.state.standardChart, measurementSystem) as StandardChart;
            }
            const userChart: UserChart = unitService.convertChart(this.state.userChart as UserChart, measurementSystem);

            this.listUserMeasureMetadata(this.state.birthdate as Moment, userChart, standardChart);

            this.setState({ measurementSystem, standardChart, userChart });
        } catch (error) {
            errorService.displayMessage(error);
            ga.createDefaultEvent('growth chart', 'growth chart - change measurement system - error');
        }
    };

    handleChangeGender = async (gender: string): Promise<void> => {
        try {
            ga.createDefaultEvent('growth chart', 'growth chart - change gender', gender);
            const availableStandardCharts: StandardChart[] = this.listAvailableStandardCharts(
                this.state.standardCharts,
                this.state.chartType as string,
                gender,
            );
            const standardChart: StandardChart | undefined = await this.getStandardChart(
                this.state.birthdate as Moment,
                this.state.measurementSystem as string,
                this.state.chartType,
                availableStandardCharts[0].id,
            );

            const userChart: UserChart = this.state.userChart as UserChart;
            this.listUserMeasureMetadata(this.state.birthdate as Moment, userChart, standardChart);

            this.setState({ gender, availableStandardCharts, standardChart });
        } catch (error) {
            errorService.displayMessage(error);
            ga.createDefaultEvent('growth chart', 'growth chart - change gender - error');
        }
    };

    handleChangeBirthdate = async (birthdateValue: Moment | null): Promise<void> => {
        try {
            ga.createDefaultEvent(
                'growth chart',
                'growth chart - change birthdate',
                birthdateValue ? birthdateValue.format('DD.MM.YYYY') : '',
            );
            if (birthdateValue) {
                const birthdate: Moment = birthdateValue as Moment;
                const standardChartId: number | undefined = this.state.standardChart && this.state.standardChart.id;
                const standardChart: StandardChart | undefined = await this.getStandardChart(
                    birthdate,
                    this.state.measurementSystem as string,
                    this.state.chartType,
                    standardChartId,
                );

                const userChart: UserChart = this.state.userChart as UserChart;
                this.listUserMeasureMetadata(birthdate, userChart, standardChart);

                this.setState({ birthdate, standardChart });
            }
        } catch (error) {
            errorService.displayMessage(error);
            ga.createDefaultEvent('growth chart', 'growth chart - change birthdate - error');
        }
    };

    handleChangeStandardChart = async (id?: number): Promise<void> => {
        try {
            ga.createDefaultEvent(
                'growth chart',
                'growth chart - change standard chart',
                id ? id.toString() : undefined,
            );
            const birthdate: Moment = this.state.birthdate as Moment;
            const standardChart: StandardChart | undefined = await this.getStandardChart(
                birthdate,
                this.state.measurementSystem as string,
                this.state.chartType,
                id,
            );

            const userChart: UserChart = this.state.userChart as UserChart;
            this.listUserMeasureMetadata(this.state.birthdate as Moment, userChart, standardChart);

            this.setState({ standardChart });
        } catch (error) {
            errorService.displayMessage(error);
            ga.createDefaultEvent('growth chart', 'growth chart - change standard chart - error');
        }
    };

    handleShowUserMeasure = async (userMeasure?: UserMeasure): Promise<void> => {
        ga.createDefaultEvent('growth chart', 'growth chart - show measure');
        this.setState({ userMeasureVisible: true, userMeasure });
    };

    handleCreateUserMeasure = async (userMeasure: UserMeasure): Promise<void> => {
        try {
            ga.createDefaultEvent('growth chart', 'growth chart - save measure');
            await this.createUserMeasure(userMeasure);
            message.success(this.props.intl.formatMessage({ id: 'common.notification.saved' }), 0.7);
        } catch (error) {
            errorService.displayMessage(error, [[409, 'biometrics.measure.error.duplicated']]);
            ga.createDefaultEvent('growth chart', 'growth chart - save measure - error');
        }
    };

    handleDeleteUserMeasure = async (userMeasure: UserMeasure): Promise<void> => {
        try {
            ga.createDefaultEvent('growth chart', 'growth chart - delete measure');
            await this.deleteUserMeasure(userMeasure);
            message.success(this.props.intl.formatMessage({ id: 'common.notification.deleted' }), 0.7);
        } catch (error) {
            errorService.displayMessage(error);
            ga.createDefaultEvent('growth chart', 'growth chart - delete measure - error');
        }
    };

    handleCloseUserMeasure = (): void => {
        ga.createDefaultEvent('growth chart', 'growth chart - close measure');
        this.setState({ userMeasureVisible: false, userMeasure: {} });
    };

    handleSave = (): void => {
        try {
            ga.createDefaultEvent('growth chart', 'growth chart - save measures');
            this.saveExternalUserMeasures();
        } catch (error) {
            errorService.displayMessage(error);
            ga.createDefaultEvent('growth chart', 'growth chart - save measures - error');
        }
    };

    /** COMPONENTS **/

    renderHeader = (): JSX.Element => {
        return (
            <div className="panel-header">
                <div>
                    <h1>
                        <FormattedMessage id={`${this.props.type}.title`} />
                    </h1>
                    <p>
                        <FormattedMessage id={`${this.props.type}.adult`} />{' '}
                        <Link
                            to={pathService.getPath(this.props.type === 'bmiTracker' ? 'growth-chart' : 'bmi-tracker')}
                        >
                            <FormattedMessage id={`${this.props.type}.adult.link`} />
                        </Link>
                    </p>
                </div>
            </div>
        );
    };

    renderChartTypeSelector = (): JSX.Element => {
        const chartTypeOptions = this.state.chartTypes.map(chartType => (
            <Select.Option value={chartType.value} key={chartType.value}>
                {chartType.label}
            </Select.Option>
        ));

        return (
            <Select
                value={this.state.chartType}
                onChange={this.handleChangeChartType}
                className={styles.type}
                size="large"
            >
                {chartTypeOptions}
            </Select>
        );
    };

    renderGenderSelector = (): JSX.Element => {
        const genderOptions = this.state.genderTypes.map(genderType => (
            <Select.Option value={genderType.value} key={genderType.value}>
                {genderType.label}
            </Select.Option>
        ));

        return (
            <>
                <label>
                    <FormattedMessage id="growthChart.gender" />
                </label>
                <Select
                    value={this.state.gender}
                    onChange={this.handleChangeGender}
                    className={styles.gender}
                    size="large"
                >
                    {genderOptions}
                </Select>
            </>
        );
    };

    renderBirthdateInput = (): JSX.Element => {
        const format: string = dateService.getDateFormat();
        return (
            <>
                <label>
                    <FormattedMessage id="growthChart.birthdate" />
                </label>
                <DatePicker
                    value={this.state.birthdate}
                    format={format}
                    disabledDate={dateService.isFutureDate}
                    allowClear={false}
                    onChange={this.handleChangeBirthdate}
                    size="large"
                    onFocus={(e: any) => (e.target.readOnly = true)}
                />
            </>
        );
    };

    renderMeasurementSystemSelector = (): JSX.Element => {
        const chartType: string = this.state.chartType as string;
        const measurementSystemOptions = this.state.measurementSystemTypes.map(measurementSystemType => {
            const unitType: UnitType = unitService.getUnitTypeByChartType(chartType, measurementSystemType.value);
            return (
                <Select.Option value={measurementSystemType.value} key={measurementSystemType.value}>
                    {chartType === 'BMI' ? measurementSystemType.label : unitType.label}
                </Select.Option>
            );
        });

        return (
            <>
                <label>
                    <FormattedMessage id="growthChart.measurementSystem" />
                </label>
                <Select
                    value={this.state.measurementSystem}
                    onChange={this.handleChangeMeasurementSystem}
                    className={styles.measurement}
                    size="large"
                >
                    {measurementSystemOptions}
                </Select>
            </>
        );
    };

    renderStandardChartSelector = (): JSX.Element => {
        const standardChartOptions = this.state.availableStandardCharts.map(tc => (
            <Select.Option value={tc.id} key={tc.id}>
                {this.props.isXs || this.props.isSm ? tc.shortName : tc.name}
            </Select.Option>
        ));
        const standardChartId: number | undefined = this.state.standardChart && this.state.standardChart.id;

        return (
            <>
                <label>
                    <FormattedMessage id="growthChart.standardChart" />
                </label>
                <Select
                    value={standardChartId}
                    allowClear
                    placeholder={<FormattedMessage id="biometrics.template.placeholder" />}
                    onChange={this.handleChangeStandardChart}
                    size="large"
                    className={styles.template}
                >
                    {standardChartOptions}
                </Select>
            </>
        );
    };

    renderGrowthChartForm = (): JSX.Element => {
        return (
            <div className={styles.form}>
                <Row gutter={[12, 12]} align="bottom" type="flex">
                    <Col className={styles.title} xs={12} sm={8}>
                        <FormattedMessage id={`${this.props.type}.chart`} />
                    </Col>
                    <Col xs={12} sm={16}>
                        {this.renderChartTypeSelector()}
                    </Col>
                    <Col xs={12} sm={8}>
                        {this.renderGenderSelector()}
                    </Col>
                    <Col xs={12} sm={8}>
                        {this.renderBirthdateInput()}
                    </Col>

                    <Col xs={24} sm={0}>
                        <Button type="primary" size="large" block onClick={e => this.handleShowUserMeasure()}>
                            <FormattedMessage id="biometrics.addMeasure" />
                        </Button>
                    </Col>
                    <Col xs={0} sm={8} className={styles.add}>
                        <Button type="primary" size="large" onClick={e => this.handleShowUserMeasure()}>
                            <FormattedMessage id="biometrics.addMeasure" />
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    };

    renderGrowthChartTemplate = (): JSX.Element => {
        return (
            <div className={styles.form}>
                <Row gutter={[12, 24]}>
                    <Col xs={16} sm={18}>
                        {this.renderStandardChartSelector()}
                    </Col>
                    <Col xs={8} sm={6}>
                        {this.renderMeasurementSystemSelector()}
                    </Col>
                </Row>
            </div>
        );
    };

    renderGrowthChartDisplay = (): JSX.Element => {
        if (this.state.userChart) {
            const measurementSystem: string = this.state.measurementSystem as string;
            const dateFormat: string = settingsService.settings.dateFormatTypes[0].value.toUpperCase();
            const chartHeight: number = this.getChartHeight();

            // get person
            const person: Person = {
                firstName: 'M',
                gender: this.state.gender,
                birthdate: this.state.birthdate,
            };

            // get template chart
            let templateChart: TemplateChart | undefined;
            if (this.state.standardChart) {
                templateChart = {
                    id: this.state.standardChart.id.toString(),
                    type: 'standard',
                    chart: this.state.standardChart,
                    name: 'test',
                    birthdate: person.birthdate,
                };
            }

            return (
                <Row className={styles.tabs}>
                    <Col span={24}>
                        <Tabs defaultActiveKey="1">
                            <Tabs.TabPane tab={<FormattedMessage id="biometrics.tabs.chart" />} key="1">
                                <BiometricsChart
                                    measurementSystem={measurementSystem}
                                    person={person}
                                    userChart={this.state.userChart}
                                    templateChart={templateChart}
                                    height={chartHeight}
                                />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={<FormattedMessage id="biometrics.tabs.measures" />} key="2">
                                <BiometricsTable
                                    measurementSystem={measurementSystem}
                                    dateFormat={dateFormat}
                                    person={person}
                                    userChart={this.state.userChart}
                                    userMeasureMetadatas={this.state.userMeasureMetadatas}
                                    status={this.state.status}
                                    showUserMeasure={this.handleShowUserMeasure}
                                />
                            </Tabs.TabPane>
                        </Tabs>
                    </Col>
                </Row>
            );
        } else {
            return <></>;
        }
    };

    renderGrowthChartUserMeasure = (): JSX.Element => {
        if (this.state.userChart) {
            const measurementSystem: string = this.state.measurementSystem as string;
            const dateFormat: string = settingsService.settings.dateFormatTypes[0].value.toUpperCase();

            // get person
            const person: Person = {
                gender: this.state.gender,
                birthdate: this.state.birthdate,
            };

            return (
                <UserMeasureModal
                    visible={this.state.userMeasureVisible}
                    external={true}
                    measurementSystem={measurementSystem}
                    dateFormat={dateFormat}
                    person={person}
                    userMeasure={this.state.userMeasure}
                    userChart={this.state.userChart}
                    standardChart={this.state.standardChart}
                    readonly={false}
                    saveUserMeasure={this.handleCreateUserMeasure}
                    deleteUserMeasure={this.handleDeleteUserMeasure}
                    closeUserMeasure={this.handleCloseUserMeasure}
                />
            );
        } else {
            return <></>;
        }
    };

    renderInfo = (): JSX.Element => {
        return (
            <div className={styles.info}>
                <h3>
                    <FormattedMessage id={`${this.props.type}.info.title`} />
                </h3>
                <p>
                    <FormattedMessage id={`${this.props.type}.info.desc`} />
                </p>
                <ul>
                    <li>
                        <FormattedMessage id={`${this.props.type}.info.1`} />
                    </li>
                    <li>
                        <FormattedMessage id={`${this.props.type}.info.2`} />
                    </li>
                    <li>
                        <FormattedMessage id={`${this.props.type}.info.3`} />
                    </li>
                    <li>
                        <FormattedMessage id={`${this.props.type}.info.4`} />
                    </li>
                </ul>
                <p>
                    <FormattedMessage id="tools.measurementSystems" />
                </p>
                <p>
                    <FormattedMessage id={`${this.props.type}.info.join`} />
                </p>
                <p>
                    <FormattedMessage id={`${this.props.type}.info.5`} />
                </p>
            </div>
        );
    };

    render() {
        return (
            <>
                <HeadMetadata
                    titleKey={`${this.props.type}.meta.title`}
                    descriptionKey={`${this.props.type}.meta.description`}
                />
                <div className={styles.layout}>
                    <Row gutter={[28, 24]}>
                        <Col xs={24} xl={19} className={styles.calculator}>
                            <div className="panel">
                                {this.renderHeader()}
                                <p className={styles.warning}>
                                    <FormattedMessage id="tools.warning" />
                                </p>
                                {this.renderGrowthChartForm()}
                                {this.renderGrowthChartDisplay()}
                                {this.renderGrowthChartTemplate()}
                                {this.renderGrowthChartUserMeasure()}
                                <GoogleAdsComponent />
                                {this.renderInfo()}
                                <GoogleAdsNewsComponent />
                            </div>
                        </Col>
                        <Col xs={0} xl={5}>
                            <SidebarComponent />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(
    injectIntl(withRouter(Form.create<Props>()(GrowthChartPage))),
);

interface Props extends WrappedComponentProps, RouteComponentProps, FormComponentProps, ScreenSizeProps {
    type: 'growthChart' | 'bmiTracker';
}

interface State {
    chartTypes: ChartType[];
    measurementSystemTypes: CustomType[];
    genderTypes: CustomType[];
    standardCharts: StandardChart[];
    availableStandardCharts: StandardChart[];

    chartType?: string;
    measurementSystem?: string;
    gender?: string;
    birthdate?: Moment;
    standardChart?: StandardChart;

    userChart?: UserChart;
    userMeasureMetadatas: UserMeasureMetadata[];
    userMeasureVisible?: boolean;
    userMeasure?: UserMeasure;
    status?: Status;
}

import React, { Component, FormEvent } from 'react';
import styles from './DashboardPage.module.scss';
import doctor from './doctor.png';
import { Row, Col, Button, Input, Select, Modal, Icon, DatePicker } from 'antd';
import CustomContext from '../../service/CustomContext';
import errorService from '../../service/ErrorService';
import personApi from '../../api/PersonApi';
import {
    ScreenSizeProps,
    User,
    ActionStatus,
    Person,
    PersonLink,
    PublicPerson,
    PublicUser,
    UserLink,
} from '../../model/model';
import responsiveService from '../../service/ResponsiveService';
import withSizes from 'react-sizes';
import Loader from '../Loader/Loader';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, WrappedComponentProps, FormattedHTMLMessage } from 'react-intl';
import userApi from '../../api/UserApi';
import Form, { FormComponentProps } from 'antd/lib/form';
import dateService from '../../service/DateService';
import moment from 'moment';
import authService from '../../service/AuthService';
import notificationApi from '../../api/NotificationApi';
import personLinkApi from '../../api/PersonLinkApi';
import userService from '../../service/UserService';
import userLinkApi from '../../api/UserLinkApi';

class DashboardPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: any) {
        super(props);
        this.state = {
            user: {},
            userType: 'PATIENT',
        };
    }

    async componentDidMount() {
        try {
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            //this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        const user = Object.assign({}, this.context.user);
        const userType = 'PATIENT';
        const person = await userService.getSelectedPerson();
        let personLink: PersonLink | undefined;
        let userLink: PersonLink | undefined;
        if (!person) {
            let notifications = await notificationApi.list();
            const personLinkNotifications = notifications.filter(n => n.notificationType === 'PERSON_LINK_INVITED');
            personLink =
                personLinkNotifications.length > 0
                    ? await personLinkApi.get(personLinkNotifications[0].targetId as number)
                    : undefined;

            const userLinkNotifications = notifications.filter(n => n.notificationType === 'USER_LINK_INVITED');
            userLink =
                userLinkNotifications.length > 0
                    ? await userLinkApi.get(userLinkNotifications[0].targetId as number)
                    : undefined;
        }
        this.setState({ user, userType, person, personLink, userLink });
    };

    saveProfessional = async (values: any) => {
        // save user
        let user: User = Object.assign({}, this.state.user, {
            userType: this.state.userType,
            firstName: values.firstName,
            lastName: values.lastName || '-',
            profession: values.profession,
            medicalSpecialities: values.medicalSpecialities,
            admin: undefined,
            associate: undefined,
        });
        user = await userApi.update(user);

        // save user link (if needed)
        await this.saveUserLink();

        // save person link (if needed)
        await this.savePersonLink();

        // refresh context user
        user = await userApi.getCurrentUser();
        const updatedUser = Object.assign({}, this.context.user, user);
        this.context.updateUser && this.context.updateUser(updatedUser);
    };

    savePatient = async (values: any) => {
        // save user
        let user: User = Object.assign({}, this.state.user, {
            userType: this.state.userType,
            firstName: values.firstName,
            lastName: values.lastName,
            profession: undefined,
            medicalSpecialities: undefined,
            admin: undefined,
            associate: undefined,
        });
        user = await userApi.update(user);

        // save person
        if (!this.state.person && !this.state.personLink) {
            const person: Person = {
                entityId: user.id,
                firstName: values.firstName,
                lastName: values.lastName,
                birthdate: values.birthdate,
                gender: values.gender,
            };
            await personApi.create(person);
        }

        // save user link (if needed)
        await this.saveUserLink();

        // save person link (if needed)
        await this.savePersonLink();

        // refresh context user
        const updatedUser = Object.assign({}, this.context.user, user);
        this.context.updateUser && this.context.updateUser(updatedUser);
    };

    savePersonLink = async (): Promise<void> => {
        if (this.state.personLinkApproval && this.state.personLink) {
            let personLink: PersonLink = Object.assign({}, this.state.personLink, {
                email: undefined,
                message: undefined,
                sent: undefined,
                inviter: undefined,
                inviterPerson: undefined,
                approver: undefined,
            });
            await personLinkApi.update(personLink);
        }
    };

    saveUserLink = async (): Promise<void> => {
        if (this.state.userLinkApproval && this.state.userLink) {
            let userLink: UserLink = Object.assign({}, this.state.userLink, {
                email: undefined,
                message: undefined,
                sent: undefined,
            });
            await userLinkApi.update(userLink);
        }
    };

    /** HANDLERS **/

    handleSaveProfessional = async (e: FormEvent) => {
        e.preventDefault();
        this.props.form.validateFields(async (error: any, values: any) => {
            try {
                if (!error) {
                    this.setState({ actionStatus: 'saving' });
                    await this.saveProfessional(values);
                    const modal = Modal.success({
                        icon: <Icon type="loading" />,
                        title: this.props.intl.formatMessage({ id: `dashboard.professional.saving` }),
                        content: this.props.intl.formatMessage({ id: `dashboard.professional.redirecting` }),
                        okButtonProps: { hidden: true },
                    });
                    setTimeout(() => {
                        modal.destroy();
                    }, 3000);
                }
            } catch (error) {
                errorService.displayMessage(error);
            } finally {
                this.setState({ actionStatus: undefined });
            }
        });
    };

    handleSavePatient = async (e: FormEvent) => {
        e.preventDefault();
        this.props.form.validateFields(async (error: any, values: any) => {
            try {
                if (!error) {
                    this.setState({ actionStatus: 'saving' });
                    await this.savePatient(values);
                    const modal = Modal.success({
                        icon: <Icon type="loading" />,
                        title: this.props.intl.formatMessage({ id: `dashboard.patient.saving` }),
                        content: this.props.intl.formatMessage({ id: `dashboard.patient.redirecting` }),
                        okButtonProps: { hidden: true },
                    });
                    setTimeout(() => {
                        modal.destroy();
                    }, 3000);
                }
            } catch (error) {
                errorService.displayMessage(error);
            } finally {
                this.setState({ actionStatus: undefined });
            }
        });
    };

    handleUserTypeChange = (userType: string): void => {
        this.setState({ userType });
    };

    handlePersonLinkApprovalChange = (personLinkApproval: boolean): void => {
        this.setState({ personLinkApproval });
    };

    handleUserLinkApprovalChange = (userLinkApproval: boolean): void => {
        this.setState({ userLinkApproval });
    };

    handleProfessionChange = (profession: string): void => {
        if (profession !== 'DOCTOR') {
            this.props.form.setFieldsValue({
                medicalSpecialities: undefined,
            });
        }
    };

    /** COMPONENTS **/

    renderHeader = (): JSX.Element => {
        return (
            <div className="panel-header">
                <div>
                    <h1>
                        <FormattedMessage id="dashboard.welcome.title" />
                    </h1>
                    <p>
                        <FormattedMessage id="dashboard.welcome.desc" />
                    </p>
                </div>
            </div>
        );
    };

    renderUserType = (): JSX.Element => {
        return (
            <>
                {this.renderHeader()}
                {this.renderUserLinkApproval()}
                <div className={styles.type} hidden={true}>
                    <h2>
                        <FormattedMessage id="dashboard.welcome.userType" />
                    </h2>
                    <Row gutter={[6, 24]}>
                        <Col xs={8}>
                            <Button
                                size="large"
                                type="primary"
                                block
                                ghost={this.state.userType !== 'PATIENT'}
                                onClick={() => this.handleUserTypeChange('PATIENT')}
                                data-test="patient"
                            >
                                <FormattedMessage id="dashboard.welcome.patient" />
                            </Button>
                        </Col>
                        <Col xs={8}>
                            <Button
                                size="large"
                                block
                                ghost={this.state.userType !== 'HEALTH_PROFESSIONAL'}
                                onClick={() => this.handleUserTypeChange('HEALTH_PROFESSIONAL')}
                                className="alternative"
                                data-test="healthProfessional"
                            >
                                <FormattedMessage id="dashboard.welcome.professional" />
                            </Button>
                        </Col>
                        <Col xs={8}>
                            <Button
                                size="large"
                                block
                                ghost={this.state.userType !== 'MEDICAL_CENTER'}
                                onClick={() => this.handleUserTypeChange('MEDICAL_CENTER')}
                                className="alternative"
                                data-test="medicalCenter"
                            >
                                <FormattedMessage id="dashboard.welcome.medicalCenter" />
                            </Button>
                        </Col>
                    </Row>
                </div>
            </>
        );
    };

    renderMedicalCenterForm = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;
        const firstNamePlaceholder: string = this.props.intl.formatMessage({
            id: 'dashboard.medicalCenter.firstName.placeholder',
        });

        return (
            <>
                {this.renderUserType()}
                <Form onSubmit={this.handleSaveProfessional}>
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <h3>
                                <FormattedMessage id="dashboard.medicalCenter.name" />
                            </h3>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col xs={24}>
                            <Form.Item>
                                {getFieldDecorator('firstName', {
                                    initialValue: this.state.user.firstName,
                                    rules: [
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: (
                                                <FormattedMessage id="dashboard.medicalCenter.firstName.error.required" />
                                            ),
                                        },
                                    ],
                                })(
                                    <Input
                                        size="large"
                                        maxLength={50}
                                        placeholder={firstNamePlaceholder}
                                        data-test="firstName"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    {this.renderPersonLinkApproval()}

                    <div className={styles.buttons}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            block={this.props.isXs || this.props.isSm}
                            loading={this.state.actionStatus === 'saving'}
                            disabled={this.state.actionStatus && this.state.actionStatus !== 'saving'}
                            data-test="submit"
                        >
                            <FormattedMessage id="common.save" />
                        </Button>
                    </div>
                </Form>
            </>
        );
    };

    renderProfessionalForm = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;
        const firstNamePlaceholder: string = this.props.intl.formatMessage({ id: 'dashboard.firstName.placeholder' });
        const lastNamePlaceholder: string = this.props.intl.formatMessage({ id: 'dashboard.lastName.placeholder' });
        const professionPlaceholder: string = this.props.intl.formatMessage({ id: 'dashboard.profession.placeholder' });
        const specialitiesPlaceholder: string = this.props.intl.formatMessage({
            id: 'dashboard.specialities.placeholder',
        });

        const professionOptions = this.context.settings.professionTypes.map(professionType => (
            <Select.Option value={professionType.value} key={professionType.value}>
                {professionType.label}
            </Select.Option>
        ));
        const medicalSpecialityOptions = this.context.settings.medicalSpecialityTypes.map(medicalSpecialityType => (
            <Select.Option value={medicalSpecialityType.value} key={medicalSpecialityType.value}>
                {medicalSpecialityType.label}
            </Select.Option>
        ));

        return (
            <>
                {this.renderUserType()}
                <Form onSubmit={this.handleSaveProfessional}>
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <h3>
                                <FormattedMessage id="dashboard.name" />
                            </h3>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col xs={12}>
                            <Form.Item>
                                {getFieldDecorator('firstName', {
                                    initialValue: this.state.user.firstName,
                                    rules: [
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: <FormattedMessage id="dashboard.firstName.error.required" />,
                                        },
                                    ],
                                })(
                                    <Input
                                        size="large"
                                        maxLength={50}
                                        placeholder={firstNamePlaceholder}
                                        data-test="firstName"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item>
                                {getFieldDecorator('lastName', {
                                    initialValue: this.state.user.lastName,
                                    rules: [
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: <FormattedMessage id="dashboard.lastName.error.required" />,
                                        },
                                    ],
                                })(
                                    <Input
                                        size="large"
                                        maxLength={50}
                                        placeholder={lastNamePlaceholder}
                                        data-test="lastName"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <h3>
                                <FormattedMessage id="dashboard.professional.profession" />
                            </h3>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col xs={24} sm={12}>
                            <div>
                                <Form.Item>
                                    {getFieldDecorator('profession', { initialValue: this.state.user.profession })(
                                        <Select
                                            size="large"
                                            onChange={this.handleProfessionChange}
                                            placeholder={professionPlaceholder}
                                        >
                                            {professionOptions}
                                        </Select>,
                                    )}
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col>
                            <div hidden={this.props.form.getFieldValue('profession') !== 'DOCTOR'}>
                                <Form.Item>
                                    {getFieldDecorator('medicalSpecialities', {
                                        initialValue: this.state.user.medicalSpecialities,
                                    })(
                                        <Select size="large" mode="multiple" placeholder={specialitiesPlaceholder}>
                                            {medicalSpecialityOptions}
                                        </Select>,
                                    )}
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>

                    {this.renderPersonLinkApproval()}

                    <div className={styles.buttons}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            block={this.props.isXs || this.props.isSm}
                            loading={this.state.actionStatus === 'saving'}
                            disabled={this.state.actionStatus && this.state.actionStatus !== 'saving'}
                            data-test="submit"
                        >
                            <FormattedMessage id="common.save" />
                        </Button>
                    </div>
                </Form>
            </>
        );
    };

    renderPersonFields = (): JSX.Element => {
        if (this.state.person || this.state.personLink) {
            return <></>;
        } else {
            const { getFieldDecorator } = this.props.form;
            const birthdatePlaceholder: string = this.props.intl.formatMessage({
                id: 'dashboard.birthdate.placeholder',
            });
            const genderOptions = this.context.settings.genderTypes.map(genderType => (
                <Select.Option value={genderType.value} key={genderType.value}>
                    {genderType.label}
                </Select.Option>
            ));

            return (
                <>
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <h3>
                                <FormattedMessage id="dashboard.patient.ageAndGender" />
                            </h3>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col xs={12}>
                            <Form.Item
                                extra={dateService.getPeriod(this.props.form.getFieldValue('birthdate'), moment())}
                            >
                                {getFieldDecorator('birthdate', {
                                    rules: [
                                        {
                                            type: 'object',
                                            required: true,
                                            message: <FormattedMessage id="dashboard.birthdate.error.required" />,
                                        },
                                    ],
                                })(
                                    <DatePicker
                                        format="DD MMM YYYY"
                                        disabledDate={dateService.isFutureDate}
                                        allowClear={false}
                                        onFocus={(e: any) => (e.target.readOnly = true)}
                                        size="large"
                                        placeholder={birthdatePlaceholder}
                                        data-test="birthdate"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item>
                                {getFieldDecorator('gender', {
                                    initialValue: 'MALE',
                                    rules: [
                                        {
                                            required: true,
                                            message: <FormattedMessage id="dashboard.gender.error.required" />,
                                        },
                                    ],
                                })(
                                    <Select size="large" data-test="gender">
                                        {genderOptions}
                                    </Select>,
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            );
        }
    };

    renderPersonLinkApproval = (): JSX.Element => {
        if (this.state.personLink) {
            const inviter = this.state.personLink.inviter as PublicUser;
            const inviterPerson = this.state.personLink.inviterPerson as PublicPerson;

            return (
                <>
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <h3>
                                <FormattedHTMLMessage
                                    id="dashboard.personLink.approval"
                                    values={{
                                        inviterName: inviter.fullName,
                                        inviterPersonName: inviterPerson.fullName,
                                    }}
                                />
                            </h3>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col xs={12}>
                            <Button
                                size="large"
                                type="primary"
                                block
                                ghost={this.state.personLinkApproval !== true}
                                onClick={() => this.handlePersonLinkApprovalChange(true)}
                                data-test="personLinkApproval"
                            >
                                <FormattedMessage id="common.yes" />
                            </Button>
                        </Col>
                        <Col xs={12}>
                            <Button
                                size="large"
                                block
                                ghost={this.state.personLinkApproval !== false}
                                onClick={() => this.handlePersonLinkApprovalChange(false)}
                                className="alternative"
                                data-test="personLinkReject"
                            >
                                <FormattedMessage id="common.no" />
                            </Button>
                        </Col>
                    </Row>
                </>
            );
        } else {
            return <></>;
        }
    };

    renderPatientForm = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;
        const firstNamePlaceholder: string = this.props.intl.formatMessage({ id: 'dashboard.firstName.placeholder' });
        const lastNamePlaceholder: string = this.props.intl.formatMessage({ id: 'dashboard.lastName.placeholder' });

        return (
            <>
                {this.renderUserType()}
                <Form onSubmit={this.handleSavePatient}>
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <h3>
                                <FormattedMessage id="dashboard.name" />
                            </h3>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col xs={12}>
                            <Form.Item>
                                {getFieldDecorator('firstName', {
                                    initialValue: this.state.user.firstName,
                                    rules: [
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: <FormattedMessage id="dashboard.firstName.error.required" />,
                                        },
                                    ],
                                })(
                                    <Input
                                        size="large"
                                        maxLength={50}
                                        placeholder={firstNamePlaceholder}
                                        data-test="firstName"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item>
                                {getFieldDecorator('lastName', {
                                    initialValue: this.state.user.lastName,
                                    rules: [
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: <FormattedMessage id="dashboard.lastName.error.required" />,
                                        },
                                    ],
                                })(
                                    <Input
                                        size="large"
                                        maxLength={50}
                                        placeholder={lastNamePlaceholder}
                                        data-test="lastName"
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    {this.renderPersonFields()}
                    {this.renderPersonLinkApproval()}
                    <div className={styles.buttons}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            block={this.props.isXs || this.props.isSm}
                            loading={this.state.actionStatus === 'saving'}
                            disabled={this.state.actionStatus && this.state.actionStatus !== 'saving'}
                            data-test="submit"
                        >
                            <FormattedMessage id="common.save" />
                        </Button>
                    </div>
                </Form>
            </>
        );
    };

    renderUserLinkApproval = (): JSX.Element => {
        if (this.state.userLink) {
            const inviter = this.state.userLink.inviter as PublicUser;

            return (
                <>
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <h3>
                                <FormattedHTMLMessage
                                    id="dashboard.userLink.approval"
                                    values={{ inviterName: inviter.fullName }}
                                />
                            </h3>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col xs={12}>
                            <Button
                                size="large"
                                type="primary"
                                block
                                ghost={this.state.userLinkApproval !== true}
                                onClick={() => this.handleUserLinkApprovalChange(true)}
                                data-test="userLinkApproval"
                            >
                                <FormattedMessage id="common.yes" />
                            </Button>
                        </Col>
                        <Col xs={12}>
                            <Button
                                size="large"
                                block
                                ghost={this.state.userLinkApproval !== false}
                                onClick={() => this.handleUserLinkApprovalChange(false)}
                                className="alternative"
                            >
                                <FormattedMessage id="common.no" />
                            </Button>
                        </Col>
                    </Row>
                </>
            );
        } else {
            return <></>;
        }
    };

    renderDashboard = (): JSX.Element => {
        if (!this.state.user.id) {
            return <Loader />;
        } else if (!authService.isUserComplete() && !this.state.userType) {
            return this.renderUserType();
        } else if (!authService.isUserComplete() && this.state.userType === 'MEDICAL_CENTER') {
            return this.renderMedicalCenterForm();
        } else if (!authService.isUserComplete() && this.state.userType !== 'PATIENT') {
            return this.renderProfessionalForm();
        } else if (!authService.isUserComplete() && this.state.userType === 'PATIENT') {
            return this.renderPatientForm();
        } else if (authService.isUserComplete() && this.state.userType !== 'PATIENT') {
            return <Redirect to="/persons" />;
        } else if (authService.isUserComplete() && this.state.userType === 'PATIENT' && this.state.person) {
            return <Redirect to={`/persons/${this.state.person.id}`} />;
        } else {
            return <Redirect to="/persons" />;
        }
    };

    render() {
        return (
            <div className={styles.layout}>
                <Row gutter={[24, 24]} type="flex">
                    <Col xs={24} lg={12} className={styles.dashboard}>
                        {this.renderDashboard()}
                    </Col>
                    <Col xs={0} lg={12} className={styles.image}>
                        {this.state.user.id && !authService.isUserComplete() && <img src={doctor} alt="doctor" />}
                    </Col>
                </Row>
            </div>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(Form.create()(withRouter(injectIntl(DashboardPage))));

interface Props extends WrappedComponentProps, RouteComponentProps, FormComponentProps, ScreenSizeProps {}

interface State {
    user: User;
    person?: Person;
    personLink?: PersonLink;
    userLink?: UserLink;
    userType?: string;
    personLinkApproval?: boolean;
    userLinkApproval?: boolean;
    actionStatus?: ActionStatus;
}

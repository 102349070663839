import React, { Component, FormEvent } from 'react';
import styles from './UserLinkPage.module.scss';
import { Form, Button, message, Popconfirm, Row, Col, Input, Icon, Select } from 'antd';
import { Status, ActionStatus, UserLink, PublicUser, User, ScreenSizeProps, Entity } from '../../../model/model';
import { FormComponentProps } from 'antd/lib/form';
import { withRouter, RouteComponentProps } from 'react-router';
import { FormattedMessage, WrappedComponentProps, injectIntl, FormattedHTMLMessage } from 'react-intl';
import errorService from '../../../service/ErrorService';
import CustomContext from '../../../service/CustomContext';
import userLinkApi from '../../../api/UserLinkApi';
import TextArea from 'antd/lib/input/TextArea';
import responsiveService from '../../../service/ResponsiveService';
import withSizes from 'react-sizes';
import moment from 'moment';
import Loader from '../../Loader/Loader';
import { Link } from 'react-router-dom';
import SidebarComponent from '../../Shared/SidebarComponent/SidebarComponent';
import HeadMetadata from '../../Helper/HeadMetadata/HeadMetadata';
import userApi from '../../../api/UserApi';

class UserLinkPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            userLink: {},
        };
    }

    async componentDidMount() {
        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        let userLink: UserLink;
        if (this.props.match.params.id && this.props.match.params.id === 'new') {
            const user = this.context.user as User;
            userLink = {
                entityId: user.entity!.id,
            };
        } else {
            userLink = await userLinkApi.get(this.props.match.params.id as number);
        }
        this.setState({ userLink });
    };

    create = async (values: any): Promise<void> => {
        let userLink: UserLink = Object.assign({}, this.state.userLink, {
            email: values.email,
            message: values.message,
        });
        userLink = await userLinkApi.create(userLink);
        this.setState({ userLink });
    };

    delete = async (): Promise<void> => {
        await userLinkApi.delete(this.state.userLink);

        // update context user to show new selected entity
        if (this.context.user && this.context.user.id === this.state.userLink.linkedUserId) {
            const user = await userApi.getCurrentUser();
            const updatedUser = Object.assign({}, this.context.user, user);
            this.context.updateUser &&
                this.context.updateUser(updatedUser, () => this.props.history.push(`/employees`));
        } else {
            this.props.history.push(`/employees`);
        }
    };

    validateEmail = (rule: any, value: any, callback: any): void => {
        const email = value as string;
        const userEmail = (this.context.user as User).email as string;
        if (email && email.trim().toLowerCase() === userEmail.trim().toLowerCase()) {
            callback(<FormattedMessage id="userLink.email.error.same" />);
        } else {
            callback();
        }
    };

    /** HANDLERS **/

    handleCreate = async (e: FormEvent): Promise<void> => {
        e.preventDefault();
        this.props.form.validateFields(async (error: any, values: any) => {
            try {
                if (!error) {
                    this.setState({ actionStatus: 'saving' });
                    await this.create(values);
                    message.success(this.props.intl.formatMessage({ id: 'common.notification.saved' }), 0.7);
                }
            } catch (error) {
                errorService.displayMessage(error, [[409, 'userLink.error.duplicated']]);
            } finally {
                this.setState({ actionStatus: undefined });
            }
        });
    };

    handleDelete = async (): Promise<void> => {
        try {
            this.setState({ actionStatus: 'deleting' });
            await this.delete();
            message.success(this.props.intl.formatMessage({ id: 'common.notification.deleted' }), 0.7);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    /** COMPONENTS **/

    renderHeader = (): JSX.Element => {
        return (
            <div className="panel-header">
                <div>
                    <h1>
                        <FormattedMessage id="navigation.userLinks" />
                    </h1>
                    <p>
                        <FormattedMessage id="navigation.userLinks.desc" />
                    </p>
                </div>
                <div className="panel-header-back">{this.renderBackButton()}</div>
            </div>
        );
    };

    renderBackButton = (): JSX.Element => {
        return (
            <Link to="/employees">
                <Button icon="arrow-left" ghost type="primary">
                    {this.props.isXs || this.props.isSm ? '' : <FormattedMessage id="common.back" />}
                </Button>
            </Link>
        );
    };

    renderCreationForm = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;
        const user = this.context.user as User;
        const entity = user.entity as Entity;
        const message = this.props.intl.formatMessage(
            {
                id: 'userLink.message.placeholder',
            },
            { entityName: entity.fullName },
        );

        return (
            <Form onSubmit={this.handleCreate}>
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <Form.Item label={<FormattedMessage id="userLink.email" />}>
                            {getFieldDecorator('email', {
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id="userLink.email.error.required" />,
                                    },
                                    {
                                        type: 'email',
                                        message: <FormattedMessage id="userLink.email.error.invalid" />,
                                    },
                                    { validator: this.validateEmail },
                                ],
                            })(
                                <Input
                                    prefix={<Icon type="user" className={styles.inputIcon} />}
                                    maxLength={100}
                                    size="large"
                                    type="email"
                                    data-test="email"
                                />,
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <Form.Item label={<FormattedMessage id="userLink.message" />}>
                            {getFieldDecorator('message', {
                                initialValue: message,
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id="userLink.message.error.required" />,
                                    },
                                ],
                            })(<TextArea maxLength={500} rows={5} data-test="message" />)}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 36]}>
                    <Col span={24}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            block={this.props.isXs || this.props.isSm}
                            loading={this.state.actionStatus === 'saving'}
                            disabled={this.state.actionStatus && this.state.actionStatus !== 'saving'}
                            data-test="submit"
                        >
                            <FormattedMessage id="userLink.invite" />
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    };

    renderPendingApproval = (): JSX.Element => {
        const sent = this.state.userLink.sent
            ? moment(this.state.userLink.sent).format('DD MMMM YYYY')
            : moment().format('DD MMMM YYYY');

        return (
            <div className={styles.invited} data-test="pendingApproval">
                <h2>
                    <Icon type="check-circle" /> <FormattedMessage id="userLink.invited.title" />
                </h2>
                <p>
                    <FormattedHTMLMessage
                        id="userLink.invited.desc"
                        values={{ email: this.state.userLink.email, sent: sent }}
                    />
                </p>
                <Popconfirm
                    title={
                        <FormattedMessage
                            id="userLink.invited.confirm.delete"
                            values={{
                                user: this.state.userLink.email,
                            }}
                        />
                    }
                    onConfirm={this.handleDelete}
                    okText={<FormattedMessage id="common.ok" />}
                    cancelText={<FormattedMessage id="common.cancel" />}
                >
                    <Button
                        type="link"
                        className={styles.cancel}
                        loading={this.state.actionStatus === 'deleting'}
                        disabled={this.state.actionStatus && this.state.actionStatus !== 'deleting'}
                        hidden={this.state.userLink.id === undefined}
                        data-test="delete"
                    >
                        <FormattedMessage id="userLink.invited.delete" />
                    </Button>
                </Popconfirm>
            </div>
        );
    };

    renderUpdateForm = (): JSX.Element => {
        const { getFieldDecorator } = this.props.form;
        const user = this.context.user as User;
        const userLink = this.state.userLink;
        const linkedUser = userLink.approver as PublicUser;
        const userName = linkedUser.fullName;
        const userTypeOptions = this.context.settings.userTypes.map(userType => (
            <Select.Option value={userType.value} key={userType.value}>
                {userType.label}
            </Select.Option>
        ));
        const professionOptions = this.context.settings.professionTypes.map(professionType => (
            <Select.Option value={professionType.value} key={professionType.value}>
                {professionType.label}
            </Select.Option>
        ));
        const isDeleteAllowed =
            userLink.id !== undefined && (userLink.entityId === user.id || userLink.linkedUserId === user.id);

        return (
            <div className={styles.layout}>
                <Form>
                    <Row gutter={[24, 0]}>
                        <Col span={12}>
                            <Form.Item label={<FormattedMessage id="user.firstName" />}>
                                {getFieldDecorator('firstName', {
                                    initialValue: linkedUser.firstName,
                                })(<Input size="large" maxLength={50} disabled />)}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={<FormattedMessage id="user.lastName" />}>
                                {getFieldDecorator('lastName', {
                                    initialValue: linkedUser.lastName,
                                })(<Input size="large" maxLength={50} disabled />)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col xs={24} sm={12}>
                            <Form.Item label={<FormattedMessage id="user.userType" />}>
                                {getFieldDecorator('userType', {
                                    initialValue: linkedUser.userType,
                                })(
                                    <Select size="large" disabled>
                                        {userTypeOptions}
                                    </Select>,
                                )}
                            </Form.Item>{' '}
                        </Col>
                        <Col xs={24} sm={12}>
                            <div hidden={linkedUser.userType === 'PATIENT'}>
                                <Form.Item label={<FormattedMessage id="user.profession" />}>
                                    {getFieldDecorator('profession', {
                                        initialValue: linkedUser.profession,
                                    })(
                                        <Select size="large" disabled>
                                            {professionOptions}
                                        </Select>,
                                    )}
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]} className={styles.buttons}>
                        <Col xs={12} md={8}></Col>
                        <Col xs={12} md={16}>
                            <Popconfirm
                                title={
                                    <FormattedMessage
                                        id="userLink.confirm.delete"
                                        values={{
                                            user: userName,
                                        }}
                                    />
                                }
                                onConfirm={this.handleDelete}
                                okText={<FormattedMessage id="common.ok" />}
                                cancelText={<FormattedMessage id="common.cancel" />}
                            >
                                <Button
                                    type="link"
                                    size="large"
                                    loading={this.state.actionStatus === 'deleting'}
                                    disabled={this.state.actionStatus && this.state.actionStatus !== 'deleting'}
                                    hidden={!isDeleteAllowed}
                                    className={styles.delete}
                                    data-test="delete"
                                >
                                    <FormattedMessage id="common.remove" />
                                </Button>
                            </Popconfirm>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    };

    renderForm = (): JSX.Element => {
        if (this.state.status === 'loading') {
            return <Loader />;
        }
        if (this.state.userLink.id && this.state.userLink.linkedUserId) {
            return this.renderUpdateForm();
        } else if (this.state.userLink.id) {
            return this.renderPendingApproval();
        } else {
            return this.renderCreationForm();
        }
    };

    render() {
        return (
            <>
                <HeadMetadata />
                <div className={styles.layout}>
                    <Row gutter={[28, 24]} type="flex">
                        <Col xs={24} xl={19}>
                            <div className="panel">
                                {this.renderHeader()}
                                {this.renderForm()}
                            </div>
                        </Col>
                        <Col xs={0} xl={5}>
                            <SidebarComponent />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(withRouter(Form.create<Props>()(UserLinkPage))));

interface Props extends FormComponentProps, RouteComponentProps, WrappedComponentProps, ScreenSizeProps {
    match: any;
}

interface State {
    userLink: UserLink;
    status?: Status;
    actionStatus?: ActionStatus;
}

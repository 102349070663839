import React, { Component } from 'react';
import styles from './SubscriptionPlan.module.scss';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { ScreenSizeProps, Plan, PlanStatus } from '../../../../model/model';
import { Descriptions, Tag } from 'antd';
import responsiveService from '../../../../service/ResponsiveService';
import withSizes from 'react-sizes';

class SubscriptionPlan extends Component<Props> {
    /** COMPONENTS **/

    renderSubscriptionTitle = (): JSX.Element => {
        if (this.props.planStatus === 'subscribe') {
            return (
                <h1>
                    <FormattedMessage id={`subscription.plan.${this.props.plan.nickname}.title.subscribe`} />
                </h1>
            );
        } else if (this.props.planStatus === 'upgrade') {
            return (
                <h1>
                    <FormattedMessage id={`subscription.plan.${this.props.plan.nickname}.title.upgrade`} />
                </h1>
            );
        } else if (this.props.planStatus === 'active') {
            return (
                <h1>
                    <FormattedMessage id={`subscription.plan.${this.props.plan.nickname}.title`} />
                    <Tag className={`${styles.tag} ${styles.active}`}>
                        <FormattedMessage id="subscription.plan.active" />
                    </Tag>
                </h1>
            );
        } else {
            return (
                <h1>
                    <FormattedMessage id={`subscription.plan.${this.props.plan.nickname}.title`} />
                    <Tag className={styles.tag} color="blue">
                        <FormattedMessage id="subscription.plan.included" />
                    </Tag>
                </h1>
            );
        }
    };

    renderSubscriptionPlan = (): JSX.Element => {
        const descriptionSize: number = this.props.isLg ? 2 : 1;

        return (
            <Descriptions bordered column={descriptionSize}>
                <Descriptions.Item label={<FormattedMessage id="subscription.plan.plan" />} span={descriptionSize}>
                    <span data-test="planId">
                        <FormattedMessage id={`subscription.plan.${this.props.plan.nickname}.name`} />
                    </span>
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage id="subscription.plan.price" />} span={descriptionSize}>
                    <span data-test="planPrice">
                        <FormattedHTMLMessage
                            id={`subscription.plan.${this.props.plan.nickname}.price`}
                            values={{
                                amount: this.props.plan.amountWithVat ? this.props.plan.amountWithVat / 100 : '',
                                currency: this.props.plan.currency && this.props.plan.currency.toUpperCase(),
                            }}
                        />
                    </span>
                </Descriptions.Item>
            </Descriptions>
        );
    };

    render() {
        return (
            <>
                {this.renderSubscriptionTitle()}
                {this.renderSubscriptionPlan()}
            </>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(SubscriptionPlan);

interface Props extends ScreenSizeProps {
    plan: Plan;
    planStatus?: PlanStatus;
}

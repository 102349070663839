import React, { Component } from 'react';
import styles from './SubscriptionTrial.module.scss';
import { ScreenSizeProps, User } from '../../../../model/model';
import responsiveService from '../../../../service/ResponsiveService';
import withSizes from 'react-sizes';
import { Col, Icon, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import CustomContext from '../../../../service/CustomContext';

class SubscriptionTrial extends Component<Props> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    /** COMPONENTS **/

    render() {
        const user: User = this.context.user as User;

        if (user && user.plan && user.plan.nickname === 'basic' && !user.plan.trial) {
            return (
                <Row gutter={[24, 24]} type="flex" className={styles.container}>
                    <Col xs={24} lg={24}>
                        <Icon type="exclamation-circle" className={styles.icon} />
                        <span className={styles.title}>
                            <FormattedMessage id="homePrices.trial.title" />
                        </span>
                    </Col>
                </Row>
            );
        } else {
            return <></>;
        }
    }
}
export default withSizes(responsiveService.mapSizesToProps)(SubscriptionTrial);

interface Props extends ScreenSizeProps {}

import React, { Component } from 'react';
import withSizes from 'react-sizes';
import personApi from '../../../api/PersonApi';
import { ScreenSizeProps } from '../../../model/model';
import CustomContext from '../../../service/CustomContext';
import errorService from '../../../service/ErrorService';
import responsiveService from '../../../service/ResponsiveService';
import GoogleAdsMenuComponent from '../GoogleAdsMenuComponent/GoogleAdsMenuComponent';
import MenuComponent from '../MenuComponent/MenuComponent';
import ToolsComponent from '../ToolsComponent/ToolsComponent';
import styles from './SidebarComponent.module.scss';

class SidebarComponent extends Component<ScreenSizeProps, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        try {
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        }
    }

    /** METHODS **/

    init = async (): Promise<void> => {
        if (this.context.user) {
            await this.count();
        }
    };

    count = async () => {
        const personsCount: number = await personApi.count();
        this.setState({ personsCount });
    };

    /** COMPONENTS **/

    render() {
        if (this.context.user) {
            return (
                <div>
                    <div className={styles.sidebar}>
                        <div>
                            <MenuComponent />
                        </div>
                    </div>
                    {this.props.isXlg && <GoogleAdsMenuComponent />}
                </div>
            );
        } else {
            return (
                <>
                    <div className={styles.sidebar}>
                        <div>
                            <ToolsComponent />
                        </div>
                    </div>
                </>
            );
        }
    }
}
export default withSizes(responsiveService.mapSizesToProps)(SidebarComponent);

interface State {
    personsCount?: number;
}

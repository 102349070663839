import React, { Component } from 'react';
import styles from './UserLinkApprovalPage.module.scss';
import { Button, message, Popconfirm, Row, Col, Icon, Modal } from 'antd';
import { Status, ActionStatus, UserLink, ScreenSizeProps, PublicUser } from '../../../model/model';
import { FormComponentProps } from 'antd/lib/form';
import { withRouter, RouteComponentProps } from 'react-router';
import { FormattedMessage, WrappedComponentProps, injectIntl, FormattedHTMLMessage } from 'react-intl';
import errorService from '../../../service/ErrorService';
import CustomContext from '../../../service/CustomContext';
import userLinkApi from '../../../api/UserLinkApi';
import responsiveService from '../../../service/ResponsiveService';
import withSizes from 'react-sizes';
import SidebarComponent from '../../Shared/SidebarComponent/SidebarComponent';
import { Link } from 'react-router-dom';
import userApi from '../../../api/UserApi';

class UserLinkApprovalPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            userLink: {},
        };
    }

    async componentDidMount() {
        try {
            this.setState({ status: 'loading' });
            await this.init();
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ status: undefined });
        }
    }

    /** METHODS **/

    init = async () => {
        if (this.props.match.params.id) {
            const userLink = await userLinkApi.get(this.props.match.params!.id);
            this.setState({ userLink });
        }
    };

    save = async (): Promise<UserLink> => {
        let userLink: UserLink = Object.assign({}, this.state.userLink, {
            email: undefined,
            message: undefined,
            sent: undefined,
            inviter: undefined,
            entity: undefined,
            approver: undefined,
        });
        userLink = await userLinkApi.update(userLink);

        // update context user to show new selected entity
        const user = await userApi.getCurrentUser();
        const updatedUser = Object.assign({}, this.context.user, user);
        this.context.updateUser && this.context.updateUser(updatedUser);

        return userLink;
    };

    delete = async (): Promise<void> => {
        await userLinkApi.delete(this.state.userLink);
        this.props.history.push(`/notifications`);
    };

    /** HANDLERS **/

    handleSave = async (): Promise<void> => {
        try {
            this.setState({ actionStatus: 'saving' });
            const userLink = await this.save();
            const entity = userLink.inviter as PublicUser;
            const entityName = entity.fullName;
            const modal = Modal.success({
                icon: <Icon type="loading" />,
                title: this.props.intl.formatMessage({ id: `userLink.approval.saving` }),
                content: this.props.intl.formatMessage({ id: `userLink.approval.redirecting` }, { entityName }),
                okButtonProps: { hidden: true },
            });
            setTimeout(() => {
                modal.destroy();
                this.props.history.push(`/persons`);
            }, 3000);
        } catch (error) {
            errorService.displayMessage(error, [[409, 'userLink.error.duplicated']]);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    handleDelete = async (): Promise<void> => {
        try {
            this.setState({ actionStatus: 'deleting' });
            await this.delete();
            message.success(this.props.intl.formatMessage({ id: 'common.notification.deleted' }), 0.7);
        } catch (error) {
            errorService.displayMessage(error);
        } finally {
            this.setState({ actionStatus: undefined });
        }
    };

    /** COMPONENTS **/

    renderHeader = (): JSX.Element => {
        const userLink = this.state.userLink;
        const inviter = userLink.inviter as PublicUser;
        return (
            <div className="panel-header" data-test="header">
                <div>
                    <h1>
                        <FormattedMessage id="navigation.links.approvals" />
                    </h1>
                    <p>
                        <FormattedHTMLMessage
                            id="userLink.approval.invitation"
                            values={{ user: inviter && inviter.fullName }}
                        />
                    </p>
                </div>
            </div>
        );
    };

    renderInvitation = (): JSX.Element => {
        const userLink = this.state.userLink;
        return <div className={styles.invitation}>{userLink.message}</div>;
    };

    renderUserLinked = (): JSX.Element => {
        if (this.state.userLink && this.state.userLink.inviter) {
            const entity = this.state.userLink.inviter as PublicUser;
            const entityName = entity.fullName;

            return (
                <p className={styles.link}>
                    <FormattedHTMLMessage id="userLink.approval.title" values={{ entityName }} />{' '}
                    <Link to="/user">
                        <FormattedMessage id="userLink.approval.title.link" />
                    </Link>
                    .
                </p>
            );
        } else {
            return <></>;
        }
    };

    renderButtons = (): JSX.Element => {
        return (
            <div className={styles.buttons}>
                <Button
                    type="primary"
                    size="large"
                    onClick={this.handleSave}
                    loading={this.state.actionStatus === 'saving'}
                    disabled={this.state.actionStatus && this.state.actionStatus !== 'saving'}
                    data-test="submit"
                >
                    <FormattedMessage id="common.accept" />
                </Button>
                <Popconfirm
                    title={
                        <FormattedMessage
                            id="userLink.approval.confirm.delete"
                            values={{
                                user: this.state.userLink.inviter && this.state.userLink.inviter.username,
                            }}
                        />
                    }
                    onConfirm={this.handleDelete}
                    okText={<FormattedMessage id="common.ok" />}
                    cancelText={<FormattedMessage id="common.cancel" />}
                >
                    <Button
                        type="link"
                        size="large"
                        loading={this.state.actionStatus === 'deleting'}
                        disabled={this.state.actionStatus && this.state.actionStatus !== 'deleting'}
                        data-test="delete"
                    >
                        <FormattedMessage id="common.reject" />
                    </Button>
                </Popconfirm>
            </div>
        );
    };

    render() {
        return (
            <Row gutter={[28, 24]} type="flex">
                <Col xs={24} xl={19} className={styles.dashboard}>
                    <div className="panel">
                        {this.renderHeader()}
                        {this.renderInvitation()}
                        {this.renderUserLinked()}
                        {this.renderButtons()}
                    </div>
                </Col>
                <Col xs={0} xl={5}>
                    <SidebarComponent />
                </Col>
            </Row>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(withRouter(UserLinkApprovalPage)));

interface Props extends FormComponentProps, RouteComponentProps, WrappedComponentProps, ScreenSizeProps {
    match: any;
}

interface State {
    userLink: UserLink;
    status?: Status;
    actionStatus?: ActionStatus;
}

import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import authService from '../../service/AuthService';

class ProtectedRoute extends Component<any> {
    render() {
        const { component: Component, location, skipSubscription, ...props } = this.props;

        if (authService.user && authService.isUserComplete() && authService.isUserSubscribed()) {
            return <Route {...props} render={props => <Component {...props} />} />;
        } else if (authService.user && authService.isUserComplete() && skipSubscription) {
            return <Route {...props} render={props => <Component {...props} />} />;
        } else if (authService.user && authService.isUserComplete()) {
            return <Redirect to="/subscriptions" />;
        } else if (authService.user) {
            return <Redirect to="/dashboard" />;
        } else {
            return <Redirect to="/signin" />;
        }
    }
}
export default ProtectedRoute;
